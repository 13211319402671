import "./App.css";
import styled from "styled-components";
import { Breakpoint } from "./utils/BreakPoints";
import {
  BoldLink,
  Colors,
  LargeHeading,
  Link,
  PageTitle,
  Paragraph,
  RegularHeading,
  SmallHeading,
} from "./utils/Typography";
import { Footer } from "./components/Footer";
import { NavBar } from "./components/NavBar";
import { ForwardArrow } from "./icons/ForwardArrow";
import { OpenArrow } from "./icons/OpenArrow";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { episodes } from "./translations/i18n";
import i18n from "i18next";

const PageWrapper = styled.div`
  width: 100%;
`;

const HeroBanner = styled.div<{ image?: string }>`
  height: 100vh;

  background-image: ${(p) => "url(" + p.image + ")"};
  background-size: cover;
  background-position: center;
  background-color: #ccc;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeroContentContainer = styled.div`
  max-width: 1280px;
  padding: 64px 16px 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    padding: 32px;
    justify-content: flex-start;
  }
  @media only screen and (min-width: ${Breakpoint.Medium}) {
    padding: 48px 64px;
  }
  @media only screen and (min-width: ${Breakpoint.Large}) {
    padding: 64px 64px 0;
  }
  @media only screen and (min-width: ${Breakpoint.Wide}) {
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;

  h1 {
    word-wrap: anywhere;
  }
`;

const TextBox = styled.div`
  background-color: ${Colors.PureBlack};
  padding: 10px 16px;
  margin-top: 48px;

  p {
    font-weight: 500;
    line-height: 24px;

    @media only screen and (min-width: ${Breakpoint.Medium}) {
      line-height: 32px;
    }
  }

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    width: 70%;
  }
  @media only screen and (min-width: ${Breakpoint.Medium}) {
    width: 60%;
  }
`;

const PageContent = styled.div`
  background-color: ${Colors.LightGray};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled.div`
  max-width: 1024px;
  margin: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    margin: 24px 32px;
  }
  @media only screen and (min-width: ${Breakpoint.Medium}) {
    margin: 24px 64px;
  }
  @media only screen and (min-width: ${Breakpoint.Large}) {
    margin: 100px 64px;
  }
`;

const PodcastBlock = styled.div`
  background-color: ${Colors.White};

  &.first {
    margin-top: 32px;

    @media only screen and (min-width: ${Breakpoint.Tablet}) {
      margin-bottom: 48px;
    }
  }
`;

const TitleBanner = styled.div`
  background-color: ${Colors.Green};

  a {
    text-decoration: none;
    color: ${Colors.Black};
    padding: 10px 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (min-width: ${Breakpoint.Tablet}) {
      padding: 14px 26px;
    }
  }

  :hover {
    background-color: #9cd200;
  }

  :active {
    background-color: ${Colors.Black};

    h2 {
      color: ${Colors.Green};
    }

    svg {
      path {
        fill: ${Colors.Green};
      }
    }
  }
`;

const ContentGrid = styled.div`
  background-color: ${Colors.White};
  padding: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    padding: 26px;
    display: grid;
    grid-template-columns: 0.5fr 2fr;
  }
`;

const ImageContainer = styled.div<{ image?: string }>`
  max-width: 100%;
  width: 300px;
  height: 300px;

  background-image: ${(p) => "url(" + p.image + ")"};
  background-size: cover;
  background-position: center;
  background-color: #ccc;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    width: 250px;
    height: 250px;
  }

  @media only screen and (min-width: ${Breakpoint.Large}) {
    width: 312px;
    height: 312px;
  }
`;

const TextContainer = styled.div`
  padding: 16px 0 0 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  svg {
    margin-left: 8px;
  }

  a {
    align-self: end;
  }

  &:hover {
    a {
      svg {
        path {
          fill: ${Colors.Black};
        }
      }
    }
  }

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    padding: 0 0 0 26px;
    height: 250px;
  }
  @media only screen and (min-width: ${Breakpoint.Large}) {
    height: 312px;
  }
`;

const PreviewButton = styled.div`
  background-color: ${Colors.PureBlack};

  max-width: 90%;
  padding: 12px;
  margin-top: 24px;

  display: flex;
  align-self: flex-end;

  @media only screen and (min-width: ${Breakpoint.Large}) {
    max-width: 40%;
  }
`;

export const PreviewText = styled.p`
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;

  color: ${Colors.White};
  margin: 0 !important;
`;

const ContactSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    margin-top: 0;
  }
`;

const BlackSection = styled.div`
  background-color: ${Colors.PureBlack};
  width: 100%;
  height: 260px;

  display: flex;
  justify-content: center;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    height: 300px;
  }

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    height: 360px;
  }
`;

const ContactWrapper = styled.div`
  max-width: 1024px;
  width: 100%;
  margin: 0 16px;

  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    margin: 0 32px;
  }

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    margin: 0 64px;
  }

  @media only screen and (min-width: ${Breakpoint.Large}) {
    margin: 0 64px;
  }
`;

const CreditWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  p {
    color: ${Colors.White};
  }
`;

const ContactBanner = styled.div`
  height: fit-content;
  background-color: ${Colors.White};

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  img {
    height: 150px;

    @media only screen and (min-width: ${Breakpoint.Tablet}) {
      height: 250px;
    }

    @media only screen and (min-width: ${Breakpoint.Medium}) {
      height: 350px;
    }
  }
`;

const TextWrapper = styled.div`
  text-align: right;
  padding: 16px;

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    padding: 26px;
  }
`;

const ContactBlock = styled.div`
  height: fit-content;
  padding: 16px;
  background-color: ${Colors.DarkGray};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    margin-bottom: 12px;
  }

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    padding: 16px 16px 32px;
  }
`;

const Links = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;

  a {
    margin-bottom: 10px;
  }
`;

const ShowTabletAndBelow = styled.div`
  display: inline-block;

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    display: none;
  }
`;

const ShowOnLaptop = styled.div`
  display: none;

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    display: inline-block;
  }
`;

function App() {
  const currentDate = new Date();

  const { t } = useTranslation();
  const lang = i18n.language;
  const title = 'Keskusteluja kaupunkimuotoilusta - podcast'
  const description = 'Digitalistin keskusteluja kaupunkimuotoilusta - podcastissa pureudutaan kaupunkimuotoiluun ja tuodaan esiin sen merkitystä erilaisten näkökulmien kautta. Pohdimme mielenkiintoisten vieraiden kanssa myös miksi kaupunkeja tulisi kehittää muotoilun menetelmin ja nostamme esiin erilaisia esimekkejä kaupunkimuotoilun saralta'
  const url = 'https://kaupunkimuotoilu.digitalist.global/'
  const ogImage = 'https://kaupunkimuotoilu.digitalist.global/home-share.png'
  return (
    <>
      <Helmet>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogImage} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={ogImage} />
      </Helmet>
      <PageWrapper>
        <HeroBanner image="/Hero.png">
          <NavBar />
          <HeroContentContainer>
            <TitleWrapper>
              {lang === "en" ? (
                <>
                  <ShowOnLaptop>
                    <PageTitle>{t("hero1")}</PageTitle>
                  </ShowOnLaptop>
                  <ShowTabletAndBelow>
                    <PageTitle>{t("hero1_1")}</PageTitle>
                    <PageTitle>{t("hero1_2")} </PageTitle>
                  </ShowTabletAndBelow>
                </>
              ) : (
                <>
                  <ShowOnLaptop>
                    <PageTitle>{t("hero1")}</PageTitle>
                    <PageTitle> {t("hero2")}</PageTitle>
                  </ShowOnLaptop>
                  <ShowTabletAndBelow>
                    <PageTitle>{t("hero1_1")}</PageTitle>
                    <PageTitle>{t("hero1_2")} </PageTitle>
                    <PageTitle>{t("hero2")}</PageTitle>
                  </ShowTabletAndBelow>
                </>
              )}
            </TitleWrapper>
            <TextBox>
              <Paragraph color={Colors.LightGray}>{t("hero_intro")}</Paragraph>
            </TextBox>
          </HeroContentContainer>
        </HeroBanner>
        <PageContent>
          <ContentContainer>
            {episodes.map((episode) => (
              <>
                {(episode.date < currentDate ||
                  episode.previewDate < currentDate) && (
                  <PodcastBlock className="first">
                    <TitleBanner>
                      <a href={episode.link}>
                        <RegularHeading>
                          {lang === "en" ? episode.en?.title : episode.fi?.title}
                        </RegularHeading>
                        <ForwardArrow />
                      </a>
                    </TitleBanner>

                    <ContentGrid>
                      <a href={episode.link}>
                        <ImageContainer image={episode.homeImage} />
                      </a>
                      <TextContainer>
                        <Paragraph>
                          {lang === "en" ? episode.en?.intro : episode.fi?.intro}
                        </Paragraph>

                        {episode.date < currentDate && episode.spotify ? (
                          <BoldLink
                            margin="28px 0 0 0"
                            href={episode.spotify}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t("listen")}
                            <OpenArrow />
                          </BoldLink>
                        ) : (
                          <PreviewButton>
                            <PreviewText>
                              {t("release")}{" "}
                              {episode.date.toLocaleDateString(
                                lang === 'en' ? 'en-US' : 'fi-FI',
                                {
                                  month: "long",
                                  day: "2-digit",
                                }
                              )}
                            </PreviewText>
                          </PreviewButton>
                        )}
                      </TextContainer>
                    </ContentGrid>
                  </PodcastBlock>
                )}
              </>
            ))}
          </ContentContainer>
          <ContactSection>
            <ContactWrapper>
              <ContactBanner>
                <img src="/Raisa.png" alt="Raisa Suihkonen" />
                <TextWrapper>
                  <LargeHeading>{t("contact1")}</LargeHeading>
                  <LargeHeading>{t("contact2")}</LargeHeading>
                  <LargeHeading>!</LargeHeading>
                </TextWrapper>
              </ContactBanner>
            </ContactWrapper>
          </ContactSection>

          <BlackSection>
            <ContactWrapper>
              <ContactBlock>
                <SmallHeading color={Colors.LightGray}>
                  Raisa Suihkonen
                </SmallHeading>
                <Paragraph color={Colors.LightGray}>
                  CX Insight Director
                </Paragraph>
                <Links>
                  <Link href="tel:+358407585400">+358 40 7585 400</Link>
                  <Link href="mailto:raisa.suihkonen@digitalistgroup.com">
                    raisa.suihkonen@digitalistgroup.com
                  </Link>
                </Links>
              </ContactBlock>
              <CreditWrapper>
                <Paragraph>{t("music")}</Paragraph>
              </CreditWrapper>
            </ContactWrapper>
          </BlackSection>
        </PageContent>
        <Footer />
      </PageWrapper>
    </>
  );
}

export default App;
