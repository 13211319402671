import { Helmet } from "react-helmet";
import BackButton from "./components/BackButton";
import { Footer } from "./components/Footer";
import { NavBar } from "./components/NavBar";
import { OpenArrowGray } from "./icons/OpenArrowGray";
import {
  SmallText,
  RegularHeading,
  Paragraph,
  Colors,
} from "./utils/Typography";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useEpisode } from "./hooks/Episode";
import { EpisodePlay, ShortSummary } from "./components/EpisodeComponents";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import { Breakpoint } from "./utils/BreakPoints";

const PageWrapper = styled.div`
  background-color: ${Colors.LightGray};
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;
    width: fit-content;
    display: block;
  }

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    margin-top: 32px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  position: absolute;
`;

const EpisodeContainer = styled.div`
  max-width: 1024px;
  margin: 84px 16px 64px;

  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    margin: 84px 32px 24px;

    display: grid;
    grid-template-columns: 1fr 0.5fr;
    gap: 16px;
    align-items: flex-start;
  }
  @media only screen and (min-width: ${Breakpoint.Medium}) {
    margin: 84px 48px 64px;
    gap: 24px;
  }
  @media only screen and (min-width: ${Breakpoint.Large}) {
    margin: 104px 64px 100px;
  }

  @media only screen and (min-width: ${Breakpoint.Wide}) {
    margin: 0 64px 100px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 300px;
  height: fit-content;

  p {
    margin-top: 12px;
  }

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    margin-top: 50px;
    width: 250px;

    p {
      margin-top: 20px;
    }
  }

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    margin-top: 80px;
    width: 312px;
  }

  @media only screen and (min-width: ${Breakpoint.Wide}) {
    width: 400px;
  }
`;

const ImageContainer = styled.div<{ image?: string }>`
  width: 300px;
  height: 300px;

  background-image: ${(p) => "url(" + p.image + ")"};
  background-size: cover;
  background-position: center;
  background-color: #ccc;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    width: 250px;
    height: 250px;
  }

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    width: 312px;
    height: 312px;
  }
  @media only screen and (min-width: ${Breakpoint.Wide}) {
    width: 400px;
    height: 400px;
  }
`;

export const TextWrapper = styled.div`
  margin-bottom: 30px;

  h2 {
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 1.25em;
  }

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    margin-bottom: 64px;

    h2 {
      margin-bottom: 50px;
    }
  }
`;

const RecommendationsBlock = styled.div`
  margin: 64px 0 0;

  .heading {
    margin-bottom: 24px;
  }

  .small-heading {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 8px;
    display: flex;
    gap: 12px;
  }

  a {
    color: ${Colors.Black};

    :hover {
      text-decoration: underline;
    }
  }

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    margin: 64px 0;

    .heading {
      margin-bottom: 32px;
    }
    .small-heading {
      margin-bottom: 16px;
    }
  }
`;

const MobileOnly = styled.div`
  display: inline-block;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    display: none;
  }
`;

const ShowOnTablet = styled.div`
  display: none;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    display: inline-block;
  }
`;

interface EpisodePageProps {
  episodeNumber: number;
  materials?: Material[];
  photoFiles: string[]; // ['Saara-Simonen.png', 'Possibly-More.png']
}

interface Material {
  url?: string;
  title: string;
  author?: string;
}

export const EpisodePage = (props: EpisodePageProps) => {
  const { t } = useTranslation();
  const episode = useEpisode("S1E" + props.episodeNumber);
  const lang = i18n.language;

  const now = new Date();
  const stillHidden = episode.previewDate > now;
  if (stillHidden) return <></>;

  const prefix = "episode" + props.episodeNumber;
  const ogTitle = episode.ogTitle;
  const ogDescription = episode.ogDescription;
  const ogImage = `https://kaupunkimuotoilu.digitalist.global/${prefix}-share.png`;
  const ogUrl = `https://kaupunkimuotoilu.digitalist.global/${prefix}`;

  const title = t(`${prefix}_title`);
  const quote = t(`${prefix}_quote`);
  const materialsHeading = t(`${prefix}_materials_heading`);
  const materials = props.materials ?? [];
  const paragraphs = t(`${prefix}_paragraphs`).split("§§§") ?? [];
  const summary = t(`${prefix}_summary`);

  const Recommendations = () => {
    return (
      <RecommendationsBlock>
        {materials.length > 0 && (
          <>
            <Paragraph bold className="heading">
              {materialsHeading}
            </Paragraph>
            {materials.map((material) => (
              <div style={{ marginBottom: "1.5em" }}>
                <MaterialRow {...material} />
              </div>
            ))}
          </>
        )}
      </RecommendationsBlock>
    );
  };

  const MaterialRow = (props: PropsWithChildren<Material>) => {
    return (
      <>
        <OptionalUrl url={props.url}>
          <SmallText bold>
            {props.title}
            {props.url && <OpenArrowGray />}
          </SmallText>
          {props.author && <SmallText>{props.author}</SmallText>}
        </OptionalUrl>
      </>
    );
  };

  const OptionalUrl = (props: PropsWithChildren<Partial<Material>>) => {
    if (!props.url) return props.children;
    return (
      <a href={props.url} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  };

  return (
    <>
      <Helmet>
        <meta name="title" content={ogTitle} />
        <meta name="description" content={ogDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ogUrl} />
        <meta name="og:title" content={ogTitle} />
        <meta name="og:description" content={ogDescription} />
        <meta property="og:image" content={ogImage} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={ogUrl} />
        <meta name="twitter:title" content={ogTitle} />
        <meta name="twitter:description" content={ogDescription} />
        <meta property="twitter:image" content={ogImage} />
      </Helmet>
      <PageWrapper>
        <NavBar isDark />

        <PageContent>
          <ButtonWrapper>
            <BackButton />
          </ButtonWrapper>

          <EpisodeContainer>
            <TextWrapper>
              <RegularHeading bold>{title}</RegularHeading>
              {paragraphs.map((paragraph) => (
                <Paragraph>{paragraph}</Paragraph>
              ))}
              {quote && <Paragraph italic>{quote}</Paragraph>}
              <EpisodePlay lang={lang} episode={episode} />
              <ShowOnTablet>
                <Recommendations />
                {summary && <ShortSummary lang={lang} text={summary} />}
              </ShowOnTablet>
            </TextWrapper>
            <div>
              {props.photoFiles.map((file, i) => {
                const caption = t(`${prefix}_caption_${i}`);
                return (
                  <ImageWrapper>
                    <ImageContainer image={`/img/face/${file}`} />
                    <SmallText dangerouslySetInnerHTML={{ __html: caption }} />
                  </ImageWrapper>
                );
              })}
            </div>
            <MobileOnly>
              <Recommendations />
              {summary && <ShortSummary lang={lang} text={summary} />}
            </MobileOnly>
          </EpisodeContainer>
        </PageContent>
        <Footer />
      </PageWrapper>
    </>
  );
};
