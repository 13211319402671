export const OpenArrowBlack = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 1C15 0.447715 14.5523 -7.91831e-09 14 3.42285e-08L5 -5.00652e-08C4.44772 -5.00652e-08 4 0.447715 4 1C4 1.55228 4.44772 2 5 2L13 2L13 10C13 10.5523 13.4477 11 14 11C14.5523 11 15 10.5523 15 10L15 1ZM0.292893 13.2929C-0.097631 13.6834 -0.0976314 14.3166 0.292893 14.7071C0.683417 15.0976 1.31658 15.0976 1.70711 14.7071L0.292893 13.2929ZM13.2929 0.292893L0.292893 13.2929L1.70711 14.7071L14.7071 1.70711L13.2929 0.292893Z"
        fill="#303030"
      />
    </svg>
  );
};
