import styled from "styled-components";
import { Breakpoint } from "./BreakPoints";

export enum Colors {
  Green = "#B2F100",
  LightGray = "#EDEDED",
  DarkGray = "#303030",
  Black = "#303030",
  PureBlack = "#000000",
  White = "#FFF",
}

// e.g. main heading on case page and story page
export const PageTitle = styled.h1`
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  font-size: 32px;

  background-color: ${Colors.Green};
  color: ${Colors.Black};

  padding: 4px 8px;
  margin: 0 0 12px 0;
  width: fit-content;

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    font-size: 48px;
    margin: 0 0 20px 0;
    padding: 12px 24px;
  }

  @media only screen and (min-width: ${Breakpoint.Large}) {
    font-size: 54px;
  }

  @media only screen and (min-width: ${Breakpoint.Wide}) {
    font-size: 64px;
  }
`;

export const LargeHeading = styled.h2<{ color?: string }>`
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 95.6%;

  color: ${(p) => (p.color ? p.color : "#303030")};
  margin: 0;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    font-size: 48px;
  }

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    font-size: 96px;
  }
`;

export const RegularHeading = styled.h2<{ color?: string; bold?: boolean }>`
  font-family: "Roboto", sans-serif;
  font-weight: ${(p) => (p.bold ? 600 : 400)};
  font-size: 20px;

  color: ${(p) => (p.color ? p.color : "#303030")};
  margin: 0;

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    font-size: 28px;
  }
`;

export const SmallHeading = styled.h3<{ color?: string }>`
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 20px;

  color: ${(p) => (p.color ? p.color : "#303030")};
  margin: 0;

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    font-size: 24px;
  }
`;

export const Paragraph = styled.p<{
  color?: string;
  bold?: boolean;
  italic?: boolean;
}>`
  font-family: "Roboto", sans-serif;
  font-weight: ${(p) => (p.bold ? 700 : 400)};
  font-style: ${(p) => (p.italic ? "italic" : "normal")};
  font-size: 16px;
  line-height: 125%;

  color: ${(p) => (p.color ? p.color : "#303030")};
  margin: 0;

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    font-size: 20px;
  }
`;

export const SmallText = styled.p<{ color?: string; bold?: boolean }>`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-weight: ${(p) => (p.bold ? 700 : 400)};

  color: ${(p) => (p.color ? p.color : "#303030")};
  margin: 0;

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    font-size: 16px;
  }
`;

export const Link = styled.a`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;

  color: ${Colors.Green};
  margin: 0;
  width: fit-content;

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    font-size: 16px;
  }
`;

export const BoldLink = styled.a<{ margin?: string; color?: string }>`
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;

  color: ${(p) => (p.color ? p.color : "#303030")};
  margin: ${(p) => (p.margin ? p.margin : 0)};
  width: fit-content;
  cursor: pointer;

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    font-size: 16px;
  }
`;
