export const OpenArrow = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 2C22.5 1.17157 21.8284 0.5 21 0.5L7.5 0.5C6.67157 0.5 6 1.17157 6 2C6 2.82843 6.67157 3.5 7.5 3.5L19.5 3.5L19.5 15.5C19.5 16.3284 20.1716 17 21 17C21.8284 17 22.5 16.3284 22.5 15.5L22.5 2ZM0.93934 19.9393C0.353554 20.5251 0.353553 21.4749 0.93934 22.0607C1.52513 22.6464 2.47487 22.6464 3.06066 22.0607L0.93934 19.9393ZM19.9393 0.93934L0.93934 19.9393L3.06066 22.0607L22.0607 3.06066L19.9393 0.93934Z"
        fill="#B2F100"
      />
    </svg>
  );
};
