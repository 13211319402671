import styled from "styled-components";
import { DigitalistLogo } from "../icons/DigitalistLogo";
import { Breakpoint } from "../utils/BreakPoints";
import { Colors, Paragraph, BoldLink } from "../utils/Typography";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  font-size: 14px;
  line-height: 24px;
  padding: 32px 16px 48px;

  background-color: ${Colors.DarkGray};
  color: ${Colors.Black};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    padding: 32px 64px 64px 32px;
  }
  @media only screen and (min-width: ${Breakpoint.Large}) {
    padding: 48px 82px 96px 48px;
  }
`;

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;

  a {
    line-height: 42px;
  }

  span {
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    text-decoration: none !important;

    line-height: 42px !important;
    color: #fff !important;
    width: fit-content !important;
    padding: 0 !important;
    border: none !important;
    cursor: pointer !important;

    @media only screen and (min-width: ${Breakpoint.Medium}) {
      font-size: 16px !important;
    }

    &:hover {
      background-color: transparent !important;
    }
  }
`;

const BlackLinkSection = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-end;

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    flex-direction: row;
  }
`;

const BlackLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    flex-direction: row;
    margin-top: 32px;
  }
`;

const SocialLink = styled.a`
  font-family: "Roboto Mono", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  color: ${Colors.LightGray};
  background-color: ${Colors.PureBlack};
  padding: 8px;

  width: fit-content;
  cursor: pointer;
  text-decoration: none;

  &:visited {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:hover {
    color: ${Colors.Green};
  }
  &:active {
    color: ${Colors.Green};
    background-color: rgba(178, 241, 0, 0.16);
  }

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    font-size: 16px;
    padding: 10px;
  }
`;

const CopyWrapper = styled.div`
  padding-top: 48px;
  display: flex;
  flex-direction: column-reverse;

  p {
    margin-top: 24px;
  }

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    flex-direction: row;
    justify-content: space-between;

    p {
      margin-top: 0;
    }
  }
`;

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <ContentColumn>
          <BlackLinkSection>
            <SocialWrapper>
              <SocialLink
                href="https://www.linkedin.com/company/digitalist-group-plc/"
                target="_blank"
                rel="noreferrer"
              >
                linkedin
              </SocialLink>

              <SocialLink
                href="https://www.instagram.com/digitalist.global/"
                target="_blank"
                rel="noreferrer"
              >
                instagram
              </SocialLink>

              <SocialLink
                href="https://www.facebook.com/DigitalistGroupPlc"
                target="_blank"
                rel="noreferrer"
              >
                facebook
              </SocialLink>

              <SocialLink
                href="https://twitter.com/DigitalistInfo"
                target="_blank"
                rel="noreferrer"
              >
                twitter
              </SocialLink>

              <SocialLink
                href="https://www.youtube.com/DigitalistGroupPlc"
                target="_blank"
                rel="noreferrer"
              >
                youtube
              </SocialLink>
            </SocialWrapper>
            <BlackLinkWrapper>
              <SocialLink href="https://digitalist.global/fi/yhteystiedot/">
                {t("contact_info")}
              </SocialLink>
            </BlackLinkWrapper>
          </BlackLinkSection>

          <LinkWrapper>
            <BoldLink
              color={Colors.LightGray}
              href="https://digitalist.global/privacy-policy/"
            >
              {t("privacy")}
            </BoldLink>

            <BoldLink
              color={Colors.LightGray}
              target="_blank"
              rel="noreferrer"
              href="https://digitalist.global/terms-conditions/"
            >
              {t("terms")}
            </BoldLink>

            <span
              color={Colors.LightGray}
              id="ot-sdk-btn"
              className="ot-sdk-show-settings"
            >
              {t("cookie")}
            </span>
          </LinkWrapper>
        </ContentColumn>
        <CopyWrapper>
          <Paragraph color={Colors.LightGray}>{t("copyright", { year: new Date().getFullYear() })}</Paragraph>
          <a href="https://digitalist.global/fi/paasivu/">
            <DigitalistLogo />
          </a>
        </CopyWrapper>
      </Wrapper>
    </Container>
  );
};
