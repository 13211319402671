export const HamburgerIcon = () => {
  return (
    <svg
      width="28"
      height="18"
      viewBox="0 0 28 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="1.25"
        x2="28"
        y2="1.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <line
        y1="9.25"
        x2="28"
        y2="9.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <line
        y1="17.25"
        x2="28"
        y2="17.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
