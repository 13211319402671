export const CloseIcon = () => {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1.46967"
        y1="25.4697"
        x2="25.4695"
        y2="1.46987"
        stroke="#000"
        strokeWidth="1.5"
      />
      <line
        x1="2.53033"
        y1="1.46967"
        x2="26.5301"
        y2="25.4695"
        stroke="#000"
        strokeWidth="1.5"
      />
    </svg>
  );
};
