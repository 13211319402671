import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import "./translations/i18n";
import App from "./App";
import * as Eps from "./Episodes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/episode0",
    element: <Eps.Episode0 />,
  },
  {
    path: "/episode1",
    element: <Eps.Episode1 />,
  },
  {
    path: "/episode2",
    element: <Eps.Episode2 />,
  },
  {
    path: "/episode3",
    element: <Eps.Episode3 />,
  },
  {
    path: "/episode4",
    element: <Eps.Episode4 />,
  },
  {
    path: "/episode5",
    element: <Eps.Episode5 />,
  },
  {
    path: "/episode6",
    element: <Eps.Episode6 />,
  },
  {
    path: "/episode7",
    element: <Eps.Episode7 />,
  },
  {
    path: "/episode8",
    element: <Eps.Episode8 />,
  },
  {
    path: "/episode9",
    element: <Eps.Episode9 />,
  },
  {
    path: "/episode10",
    element: <Eps.Episode10 />,
  },
  {
    path: "/episode11",
    element: <Eps.Episode11 />,
  },
  {
    path: "/episode12",
    element: <Eps.Episode12 />,
  },
  {
    path: "/episode13",
    element: <Eps.Episode13 />,
  },
  {
    path: "/episode14",
    element: <Eps.Episode14 />,
  },
  {
    path: "/episode15",
    element: <Eps.Episode15 />,
  },
  {
    path: "/episode16",
    element: <Eps.Episode16 />,
  },
  {
    path: "/episode17",
    element: <Eps.Episode17 />,
  },
  {
    path: "/episode18",
    element: <Eps.Episode18 />,
  },
  {
    path: "*",
    element: <App />,
  },
]);

const container = document.getElementById("root");

if (container && container.hasChildNodes()) {
  hydrateRoot(
    container,
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
} else if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}
