export const OpenArrowGray = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1C14 0.447715 13.5523 -7.91831e-09 13 3.42285e-08L4 -5.00652e-08C3.44772 -5.00652e-08 3 0.447715 3 1C3 1.55228 3.44772 2 4 2L12 2L12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10L14 1ZM0.292893 12.2929C-0.0976313 12.6834 -0.0976317 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L0.292893 12.2929ZM12.2929 0.292893L0.292893 12.2929L1.70711 13.7071L13.7071 1.70711L12.2929 0.292893Z"
        fill="#ABABAB"
      />
    </svg>
  );
};
