import styled from "styled-components";
import { BackArrow } from "../icons/BackArrow";
import { Colors, SmallText } from "../utils/Typography";
import { useTranslation } from "react-i18next";

const ButtonLink = styled.a`
  :hover {
    background-color: #9cd200;
  }

  :active {
    background-color: ${Colors.Black};

    p {
      color: ${Colors.Green};
    }

    svg {
      path {
        fill: ${Colors.Green};
      }
    }
  }
`;

const LinkButton = styled.button`
  background-color: ${Colors.Green};
  padding: 16px;
  border: none;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 16px;
  z-index: 1;
  position: relative;

  p {
    margin: 0;
    font-weight: 700;
  }
`;

const BackButton = () => {
  const { t } = useTranslation();
  return (
    <>
      <ButtonLink href="/">
        <LinkButton>
          <BackArrow />
          <SmallText>{t("back")}</SmallText>
        </LinkButton>
      </ButtonLink>
    </>
  );
};

export default BackButton;
