import { episodes } from '../translations/i18n';

export const useEpisode = (id: string) => {
    const episode = episodes.find(r => r.id === id)
    // console.log({ episode })
    if (!episode) {
      throw new Error('episode data not found')
    }
    return episode
  }
  