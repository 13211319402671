import i18n, { Resource } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { Episode } from "../react-app-env";

export const episodes: Episode[] = [
  {
    id: "S1E0",
    ogTitle: "0. Tervetuloa keskustelemaan kaupunkimuotoilusta",
    ogDescription:
      "Tässä jaksossa johdatamme kuulijamme Keskusteluja kaupunkimuotoilusta-podcastin pariin ja kerromme lyhyesti mitä ensimmäiseltä kaudelta on odotettavissa.",
    fi: {
      title: "0 \\ Päivi Vartiainen & Saara-Sisko Jäämies, Digitalist",
      intro: `Tässä jaksossa johdatamme kuulijamme Keskusteluja kaupunkimuotoilusta-podcastin pariin ja kerromme lyhyesti mitä ensimmäiseltä kaudelta on odotettavissa.`,
    },
    en: {
      title: "0 \\ Päivi Vartiainen & Saara-Sisko Jäämies, Digitalist",
      intro: `In the first trailer episode, “Welcome to discuss City design” we introduce our listeners to the podcast and briefly tell you what to expect from the first season.`,
    },
    homeImage: "/Episode0.png",
    previewDate: new Date("2023-08-30T09:00:00Z"),
    date: new Date("2023-08-30T09:00:00Z"),
    link: "/episode0",
    spotify: "https://open.spotify.com/episode/1nuNf3IAIzkxkc5Gyltnjm",
  },
  {
    id: "S1E1",
    ogTitle: "1. Miksi kaupunkeja tulisi kehittää muotoilun keinoin?",
    ogDescription:
      "Tässä jaksossa pohdimme mitä kaupunkimuotoilu oikeastaan on. Keskustelemme myös siitä, miten muotoilun keinoja tulee ja voidaan hyödyntää kaupunkien kehityksessä.",
    fi: {
      title: "1 \\ Sara Ikävalko, LAB-ammattikorkeakoulu",
      intro: `Tässä jaksossa pohdimme mitä kaupunkimuotoilu oikeastaan on. Keskustelemme myös siitä, miten muotoilun keinoja tulee ja voidaan hyödyntää kaupunkien kehityksessä.`,
    },
    en: {
      title: "1 \\ Sara Ikävalko, LAB University of Applied Sciences",
      intro: `In this episode, “Why should design methods be used in city development?”, we consider what city design actually is. We also discuss how design methods are and can be utilised in the development of cities.`,
    },
    homeImage: "/Episode1.png",
    previewDate: new Date("2023-09-06T06:00:00Z"),
    date: new Date("2023-09-08T07:00:00Z"),
    link: "/episode1",
    spotify: "https://open.spotify.com/episode/7KJWfJfjchb2HmG9VKA2T3",
  },
  {
    id: "S1E2",
    ogTitle: "2. Mitä kaupungit voisivat oppia liikemaailmasta?",
    ogDescription:
      "Tämänkertaisessa jaksossa keskustelemme siitä, mitä liikemaailman oppeja voidaan soveltaa kaupunkien kehityksessä. Pohdimme myös kuinka kaupunkien asiakaskokemusta voidaan kehittää tavoitteellisesti.",
    fi: {
      title: "2 \\ Johanna Partti, Kaupunkiliikenne Oy",
      intro: `Tämänkertaisessa jaksossa keskustelemme siitä, mitä liikemaailman oppeja voidaan soveltaa kaupunkien kehityksessä. Pohdimme myös kuinka kaupunkien asiakaskokemusta voidaan kehittää tavoitteellisesti.`,
    },
    en: {
      title: "2 \\ Johanna Partti, Kaupunkiliikenne Oy",
      intro: `In this episode, “What could cities learn from the business world?”, we discuss what lessons from the business world can be applied in the development of cities. We also consider how the customer experience in cities can be developed in a goal-oriented way.`,
    },
    homeImage: "/Episode2.png",
    previewDate: new Date("2023-09-14T07:00:00Z"),
    date: new Date("2023-09-19T07:00:00Z"),
    link: "/episode2",
    spotify: "https://open.spotify.com/episode/7w5NoPcJCogTQ6xza6fh2x",
  },
  {
    id: "S1E3",
    ogTitle: "3. Miksi empatia ja ihmiskeskeisyys on tärkeää?",
    ogDescription:
      "Tällä kertaa syvennymme empatiaan ja ihmis-keskeisyyteen ja pohdimme miksi nämä ovat tärkeitä seikkoja myös kaupunkimuotoilussa. Lisäksi jaksossa kuullaan konkreettinen esimerkki empatian hyödyntämisestä Hel.fi-sivustouudistuksesta.",
    fi: {
      title: "3 \\ Anni Leppänen, Helsingin kaupunki",
      intro: `Tällä kertaa syvennymme empatiaan ja ihmis-keskeisyyteen ja pohdimme miksi nämä ovat tärkeitä seikkoja myös kaupunkimuotoilussa. Lisäksi jaksossa kuullaan konkreettinen esimerkki empatian hyödyntämisestä Hel.fi-sivustouudistuksesta.`,
    },
    en: {
      title: "3 \\ Anni Leppänen, City of Helsinki",
      intro: `In this episode, “Why is empathy and being human-centred important?”, we delve deeper into empathy and human-centredness and consider why these are important issues also in city design. In addition, a concrete example of using empathy during the Hel.fi website renewal is shared.`,
    },
    homeImage: "/Episode3.png",
    previewDate: new Date("2023-09-28T07:00:00Z"),
    date: new Date("2023-10-03T07:00:00Z"),
    link: "/episode3",
    spotify: "https://open.spotify.com/episode/3J1JzmsGv9dwtcDt5vMoQF",
  },
  {
    id: "S1E4",
    ogTitle: "4. Miten huomioida heikoimmassa asemassa olevia?",
    ogDescription:
      "Tässä jaksossa avaamme kaupunkiteologiaa ja kerromme miten se liittyy kaupunkien muotoiluun. Keskustelemme myös siitä kuinka kaupunkilaisten halua osallistua ja vaikuttaa voidaan tukea niin, että myös heikoimmassa asemassa olevat tulevat huomioiduiksi.",
    fi: {
      title: "4 \\ Henrietta Grönlund, Helsingin Yliopisto",
      intro: `Tässä jaksossa avaamme kaupunkiteologiaa ja kerromme miten se liittyy kaupunkien muotoiluun. Keskustelemme myös siitä kuinka kaupunkilaisten halua osallistua ja vaikuttaa voidaan tukea niin, että myös heikoimmassa asemassa olevat tulevat huomioiduiksi.`,
    },
    en: {
      title: "4 \\ E4 Henrietta Grönlund, University of Helsinki",
      intro: `In this episode, “How to consider those who are in a weaker position?”, we open up the concept of urban theology and explain how it relates to the design of cities. We will also discuss how the desire of the citizens to participate and influence can be supported so that those in the weakest position are also taken into account.`,
    },
    homeImage: "/Episode4.png",
    previewDate: new Date("2023-10-12T07:00:00Z"),
    date: new Date("2023-10-17T07:00:00Z"),
    link: "/episode4",
    spotify: "https://open.spotify.com/episode/6TiymSDcK23HZDnpu7H94U",
  },
  {
    id: "S1E5",
    ogTitle: "5. Miten kehittää kaupunkeja paremmin yhdessä?",
    ogDescription:
      "Tällä kertaa pureudumme yhteiskehittämiseen ja yhteistyön parantamiseen kaupunkien muotoilussa. Keskitymme erityisesti siihen, kuinka yhteistyötä voidaan parantaa kaupunkiorganisaation sisällä.",
    fi: {
      title: "5 \\ Johanna Sinkkonen, Helsingin kaupunki",
      intro: `Tällä kertaa pureudumme yhteiskehittämiseen ja yhteistyön parantamiseen kaupunkien muotoilussa. Keskitymme erityisesti siihen, kuinka yhteistyötä voidaan parantaa kaupunkiorganisaation sisällä.`,
    },
    en: {
      title: "5 \\ Johanna Sinkkonen, City of Helsinki",
      intro: `In this episode, “How to better develop cities together?”, we talk about co-development and improving cooperation in the design of cities. We focus especially on how cooperation can be improved within the city organisation.`,
    },
    homeImage: "/Episode5.png",
    previewDate: new Date("2023-10-26T07:00:00Z"),
    date: new Date("2023-10-31T08:00:00Z"),
    link: "/episode5",
    spotify: "https://open.spotify.com/episode/7MiBt6aXDjRH4t9Bni95sI",
  },
  {
    id: "S1E6",
    ogTitle: "6. Miten kehittää kestävästi muotoilun keinoin?",
    ogDescription:
      "Tämänkertaisessa jaksossa keskustelemme siitä, kuinka muotoilua tulisi hyödyntää kaupunkien kestävässä kehityksessä. Kuulemme myös kuinka backcasting-menetelmä liittyy kaupunkien muotoiluun, sekä kuinka dataa voidaan hyödyntää kaupunkimuotoilussa.",
    fi: {
      title: "6 \\ Aleksi Neuvonen, Demos Helsinki",
      intro: `Tämänkertaisessa jaksossa keskustelemme siitä, kuinka muotoilua tulisi hyödyntää kaupunkien kestävässä kehityksessä. Kuulemme myös kuinka backcasting-menetelmä liittyy kaupunkien muotoiluun, sekä kuinka dataa voidaan hyödyntää kaupunkimuotoilussa.`,
    },
    en: {
      title: "6 \\ Aleksi Neuvonen, Demos Helsinki",
      intro: `In this episode, “How to develop sustainably through design?”, we discuss how design should be used in the sustainable development of cities. We will also hear how the backcasting method is related to city design, and how data can be utilised in designing cities.`,
    },
    homeImage: "/Episode6.png",
    previewDate: new Date("2023-11-09T08:00:00Z"),
    date: new Date("2023-11-14T08:00:00Z"),
    link: "/episode6",
    spotify: "https://open.spotify.com/episode/59RDdfX6X9cRznoDdaVzBa",
  },
  {
    id: "S1E7",
    ogTitle: "7. Miten muotoilu ja politiikka kohtaavat?",
    ogDescription:
      "Tällä kertaa keskustelemme siitä, miten asukastietoa voidaan hyödyntää päätöksenteon tukena. Pohdimme myös minkälaisia vaikutuksia kaupunkimuotoilulla ja empaattisella asukastiedolla voisi olla tulevaisuuden kaupunkien päätöksenteossa.",
    fi: {
      title: "7 \\ Elina Rantanen, Apulaispormestari, Turku",
      intro: `Tällä kertaa keskustelemme siitä, miten asukastietoa voidaan hyödyntää päätöksenteon tukena. Pohdimme myös minkälaisia vaikutuksia kaupunkimuotoilulla ja empaattisella asukastiedolla voisi olla tulevaisuuden kaupunkien päätöksenteossa.`,
    },
    en: {
      title: "7 \\ Elina Rantanen, City of Turku",
      intro: `In this episode, “How do design and politics meet?”, we talk about how resident information can be used to support decision-making. We also consider what kind of effects urban design and empathic population information could have on decision-making in future cities.`,
    },
    homeImage: "/Episode7.png",
    previewDate: new Date("2023-11-23T08:00:00Z"),
    date: new Date("2023-11-28T08:00:00Z"),
    link: "/episode7",
    spotify: "https://open.spotify.com/episode/4qC8VJxfjr9vXdL70D1MnQ",
  },
  {
    id: "S1E8",
    ogTitle: "8. Kuuntelijan poiminnat: Mitä olen oppinut kaupunkimuotoilusta?",
    ogDescription:
      "Tällä kertaa pureudumme kauden varrella esiin nousseisiin teemoihin kuuntelijan näkökulmasta. Pohdimme myös mitkä asiat kuluneelta kaudelta ovat erityisesti jääneet mieleen.",
    fi: {
      title: "8 \\ Johanna Pikkarainen, Kirkkonummen kunta",
      intro: `Tällä kertaa pureudumme kauden varrella esiin nousseisiin teemoihin kuuntelijan näkökulmasta. Pohdimme myös mitkä asiat  kuluneelta kaudelta ovat erityisesti jääneet mieleen.`,
    },
    en: {
      title: "8 \\ Johanna Pikkarainen, Kirkkonummi",
      intro: `This time, we discuss themes that emerged during the season from the listener's point of view. We also reflect on which things from the past season have particularly stuck in our minds.`,
    },
    homeImage: "/Episode8.png",
    previewDate: new Date("2023-12-12T08:00:00Z"),
    date: new Date("2023-12-18T08:00:00Z"),
    link: "/episode8",
    spotify: "https://open.spotify.com/episode/44DBvZg4IgXB8xgFKXfARt",
  },
  {
    id: "S1E9",
    ogTitle: "9. Mitä on Customer Journey Ops?",
    ogDescription:
      "Tässä jaksossa keskustelemme Customer Journey Opsista. Pohdimme mitä CJO tarkoittaa, miten ja miksi sitä tehdään ja miksi siihen kannattaa satsata myös julkisen sektorin organisaatioissa ja asiakaskokemuksen kehittämisessä.",
    fi: {
      title: "9 \\ Kaarin Laaneots, Helsingin kaupunki",
      intro: `Tässä jaksossa keskustelemme Customer Journey Opsista. Pohdimme mitä CJO tarkoittaa, miten ja miksi sitä tehdään ja miksi siihen kannattaa satsata myös julkisen sektorin organisaatioissa ja asiakaskokemuksen kehittämisessä.`,
    },
    en: {
      title: "9 \\ Kaarin Laaneots, City of Helsinki",
      intro: `In this episode, we discuss Customer Journey Ops. We consider what CJO means, how and why it is done, and why it is worth investing in it also in public sector organisations and in the development of customer experience.`,
    },
    homeImage: "/Episode9.png",
    previewDate: new Date("2024-04-16T08:00:00Z"),
    date: new Date("2024-04-16T08:00:00Z"),
    link: "/episode9",
    spotify: "https://open.spotify.com/episode/6A6xkSrDUjCpPqW4W0Kg3r",
  },
  {
    id: "S1E10",
    ogTitle: "10. Mitä ekosysteemifasilitoinnilla saavutetaan?",
    ogDescription:
      "Tässä jaksossa keskustelemme ekosysteemeistä ja ekosysteemifasilitoinnista. Usein kimuranttien ongelmien ratkaisemisessa ekosysteemimäinen toimintamalli on välttämätön. Jaksossa kuulet arjen esimerkkejä ekosysteemimäisestä toiminnasta ja vinkkejä miten ekosysteemien fasilitoinnissa kannattaa lähteä liikkeelle.",
    fi: {
      title: "10 \\ Saara Simonen, Helsingin Diakonissalaitos",
      intro: `Tässä jaksossa keskustelemme ekosysteemeistä ja ekosysteemifasilitoinnista. Usein kimuranttien ongelmien ratkaisemisessa ekosysteemimäinen toimintamalli on välttämätön. Jaksossa kuulet arjen esimerkkejä ekosysteemimäisestä toiminnasta ja vinkkejä miten ekosysteemien fasilitoinnissa kannattaa lähteä liikkeelle.`,
    },
    en: {
      title: "10 \\ Saara Simonen, Deaconess Foundation",
      intro: `In this episode, we discuss ecosystems and ecosystem facilitation. Often, in solving complex problems, an ecosystem-like approach is necessary. In the episode, you will hear everyday examples of ecosystem-like activity and tips on how to get started in facilitating ecosystems.`,
    },
    homeImage: "/Episode10.png",
    previewDate: new Date("2024-05-03T08:00:00Z"),
    date: new Date("2024-05-07T05:00:00Z"),
    link: "/episode10",
    spotify: "https://open.spotify.com/episode/3BG53mwBENzm2zsTJNRl2V",
  },
  {
    id: "S1E11",
    ogTitle: "11. Miten hyödyntää laadullista tutkimusta kaupunkimuotoilussa?",
    ogDescription:
      "Tässä jaksossa keskustelemme laadullisesta tutkimuksesta kaupunkimuotoilussa.",
    fi: {
      title: "11 \\ Lotta Nivari-Lindström ja Jaana Wikström, Digitalist",
      intro: `Tässä jaksossa keskustelemme laadullisesta tutkimuksesta kaupunkimuotoilussa.`,
    },
    en: {
      title: "11 \\ Lotta Nivari-Lindström and Jaana Wikström, Digitalist",
      intro: `In this episode we talk about qualitative research in City Design.`,
    },
    homeImage: "/Episode11.png",
    previewDate: new Date("2024-05-24T06:00:00Z"),
    date: new Date("2024-05-28T06:00:00Z"),
    link: "/episode11",
    spotify: "https://open.spotify.com/episode/3GT8Gwvtm4Pc7o2Q46U1zN",
  },
  {
    id: "S1E12",
    ogTitle: "12. Miten tekoäly vaikuttaa kaupunkien kehittämiseen",
    ogDescription:
      "Tässä jaksossa keskustelemme ja pohdiskelemme tekoälyn hyödyntämistä kaupunkien kehittämisessä ja kaupunkimuotoilussa. Keskustelemme erilaisista tekoälykokeiluista, haasteista ja onnistumisista sekä pohdimme millaisiin tilanteisiin tekoälyn käyttö sopii ja ei sovi ja mitkä ovat tekoälyn käytön eettiset puolet. Jakso on nauhoitettu toukokuussa 2024.",
    fi: {
      title: "12 \\ Maria Suutari-Jääskö ja Inka Saini",
      intro: `Tässä jaksossa keskustelemme ja pohdiskelemme tekoälyn hyödyntämistä kaupunkien kehittämisessä ja kaupunkimuotoilussa. Keskustelemme erilaisista tekoälykokeiluista, haasteista ja onnistumisista sekä pohdimme millaisiin tilanteisiin tekoälyn käyttö sopii ja ei sovi ja mitkä ovat tekoälyn käytön eettiset puolet. Jakso on nauhoitettu toukokuussa 2024.`,
    },
    en: {
      title: "12 \\ Maria Suutari-Jääskö and Inka Saini",
      intro: `In this episode, we discuss and reflect on the use of artificial intelligence in the urban development and city design. We talk about various AI experiments, challenges, and successes, and contemplate in which situations the use of AI is appropriate or not, as well as the ethical aspects of using AI. This episode was recorded in May 2024.`,
    },
    homeImage: "/Episode12.png",
    previewDate: new Date("2024-06-25T04:00:00Z"),
    date: new Date("2024-06-28T04:00:00Z"),
    link: "/episode12",
    spotify: "https://open.spotify.com/episode/58FiVkOTx6YOp6kDec5Ad1",
  },
  {
    id: "S1E13",
    ogTitle: "13. Mitä huomioida kaupunkien innovaatiotoiminnassa?",
    ogDescription:
      "Tässä jaksossa keskustelemme kaupunkien kehittämisestä, muotoilusta ja innovaatiotoiminnasta ja niihin liittyvästä hankauksesta. Pohdimme miten kaupungit selviävät monimutkaisessa toimintaympäristössä ja sitä millaisia kyvykkyyksiä kehittäminen ja kehittämiskumppanuudet kaupungilta vaatii.",
    fi: {
      title: "13 \\ Tommi Laitio",
      intro: `Tässä jaksossa keskustelemme kaupunkien kehittämisestä, muotoilusta ja innovaatiotoiminnasta ja niihin liittyvästä hankauksesta. Pohdimme miten kaupungit selviävät monimutkaisessa toimintaympäristössä ja sitä millaisia kyvykkyyksiä kehittäminen ja kehittämiskumppanuudet kaupungilta vaatii.`,
    },
    en: {
      title: "13 \\ Tommi Laitio",
      intro: `In this episode, we explore the challenges and complexities of City design, and innovation. We'll discuss the friction that arises in transforming cities, the crucial skills needed, and the partnerships that make it possible. Join us as we dive into how cities navigate their complex environments to foster innovation and growth.`,
    },
    homeImage: "/Episode13.png",
    previewDate: new Date("2024-08-16T04:00:00Z"),
    date: new Date("2024-08-20T04:00:00Z"),
    link: "/episode13",
    spotify: "https://open.spotify.com/episode/331Q5XxEux2USHDA7vTPyb",
  },
  {
    id: "S1E14",
    ogTitle: "14. Miten asiakaskokemuksen kehittäminen vahvistaa demokratiaa?",
    ogDescription: `Tässä jaksossa keskustelemme asiakaskokemuksesta, viestinnästä ja palvelumuotoilusta ja siitä, miten näiden yhdistäminen voi vahvistaa demokratiaa ja kuulluksi tulemisen kokemuksia.`,
    fi: {
      title: "14 \\ Elina Piskonen, Asiakaskokemuspäällikkö",
      intro: `Tässä jaksossa keskustelemme asiakaskokemuksesta, viestinnästä ja palvelumuotoilusta ja siitä, miten näiden yhdistäminen voi vahvistaa demokratiaa ja kuulluksi tulemisen kokemuksia.`,
    },
    en: {
      title: "14 \\ Elina Piskonen, Customer Experience Manager",
      intro: `In this episode we discuss customer experience, communication, and service design, and how combining these can strengthen democracy and the experience of being heard.`,
    },
    homeImage: "/Episode14.png",
    previewDate: new Date("2024-09-06T04:00:00Z"),
    date: new Date("2024-09-10T04:00:00Z"),
    link: "/episode14",
    spotify: "https://open.spotify.com/episode/7ghSFPg21jJONXKXnWu32h",
  },
  {
    id: "S1E15",
    ogTitle: "15. Miten vahvistaa kaupunkien muotoilumaturiteettia?",
    ogDescription: `Tässä jaksossa keskustelemme muotoilumaturiteetista ja pohdimme, millä keinoin sitä voidaan vahvistaa organisaatioiden sisäisissä prosesseissa ja madaltaa kynnystä muotoiluajatteluun.`,
    fi: {
      title: "15 \\ Katja Kumpulainen, palvelumuotoilija",
      intro: `Tässä jaksossa keskustelemme muotoilumaturiteetista ja pohdimme, millä keinoin sitä voidaan vahvistaa organisaatioiden sisäisissä prosesseissa ja madaltaa kynnystä muotoiluajatteluun.`,
    },
    en: {
      title: "15 \\ Katja Kumpulainen, Service Designer",
      intro: `In this episode, we discuss about design maturity and explore ways to strengthen it within organizational processes and lower the barrier to design thinking.`,
    },
    homeImage: "/Episode15.png",
    previewDate: new Date("2024-09-27T04:00:00Z"),
    date: new Date("2024-10-01T04:00:00Z"),
    link: "/episode15",
    spotify: "https://open.spotify.com/episode/25t0Qv1lVmeLIYcOEEZGmq",
  },
  {
    id: "S1E16",
    ogTitle: "16. Miten asiakaskokemuksen kehittäminen vahvistaa demokratiaa?",
    ogDescription: `Tässä jaksossa keskustelemme lapsikeskeisestä palvelumuotoilusta ja palveluiden kehittämisestä lapsen silmin. Pohdimme mitä on lapsen palvelukokemus ja miten sitä voidaan tukea.`,
    fi: {
      title: "16 \\ Maija Hansen, palvelumuotoilija",
      intro: `Tässä jaksossa keskustelemme lapsikeskeisestä palvelumuotoilusta ja palveluiden kehittämisestä lapsen silmin. Pohdimme mitä on lapsen palvelukokemus ja miten sitä voidaan tukea.`,
    },
    en: {
      title: "16 \\ Maija Hansen, Service Designer",
      intro: `This episode explores the principles of designing services with a focus on children and how to tailor these services from a child's viewpoint. We examine what defines a child's experience with services and discuss strategies for improving it.`,
    },
    homeImage: "/Episode16.png",
    previewDate: new Date("2024-10-18T04:00:00Z"),
    date: new Date("2024-10-22T04:00:00Z"),
    link: "/episode16",
    spotify: "https://open.spotify.com/episode/14CpxBbkD6IqQjgLlWB9bs",
  },
  {
    id: "S1E17",
    ogTitle: "17. Miltä kaupunki näyttää nuoren muotoilijan silmin?",
    ogDescription: `Tässä jaksossa podcast-studiomme ovat ottaneet valtaansa kaupunkimuotoilua LAB-ammattikorkeakoulussa opiskelleet alumni Henna Partanen ja pian valmistuva Pyry Viitikko. Pyry ja Henna keskustelevat kaupunkikokemuksesta eri käyttäjäryhmien näkökulmasta, jakaen havaintojaan sekä kokemuksiaan erilaisten kaupunkimuotoilun projektien parissa.`,
    fi: {
      title: "17 \\ Henna Partanen ja Pyry Viitikko",
      intro: `Tässä jaksossa podcast-studiomme ovat ottaneet valtaansa kaupunkimuotoilua LAB-ammattikorkeakoulussa opiskelleet alumni Henna Partanen ja pian valmistuva Pyry Viitikko. Pyry ja Henna keskustelevat kaupunkikokemuksesta eri käyttäjäryhmien näkökulmasta, jakaen havaintojaan sekä kokemuksiaan erilaisten kaupunkimuotoilun projektien parissa.`,
    },
    en: {
      title: "17 \\ Henna Partanen and Pyry Viitikko",
      intro: `In this episode Pyry and Henna discuss about the experience from the perspective of different user groups in the city context, sharing their observations and experiences in different urban design projects.`,
    },
    homeImage: "/Episode17.png",
    previewDate: new Date("2024-11-08T04:00:00Z"),
    date: new Date("2024-11-12T04:00:00Z"),
    link: "/episode17",
    spotify: "https://open.spotify.com/episode/3wItZXTEWnHuBsEcOWV5Mr",
  },
  {
    id: "S1E18",
    ogTitle:
      "18. Miten taide, luovuus ja muotoilu kohtaavat tulevaisuuksien kaupunkien muotoilussa?",
    ogDescription: `Tässä jaksossa keskustelemme tulevaisuusajattelusta ja tulevaisuusmuotoilusta. Pohdimme miksi tulevaisuusajattelu on tärkeää ja miten se voi vauhdittaa kaupunkien kehittämistä, mutta myös moniäänisyyttä ja yhteistä tarinaa. Jaksossa kuulet, miten taide ja luovuus voi viritellä tulevaisuuksien tutkimista ja tulevaisuustekoja ja luoda toivoa ja uskoa meille kaikille tässä haastavien globaalien kriisien ajassa ja maailmassa.`,
    fi: {
      title: "18 \\ Auri Antinranta ja Minna Koskelo",
      intro: `Tässä jaksossa keskustelemme tulevaisuusajattelusta ja tulevaisuusmuotoilusta. Pohdimme miksi tulevaisuusajattelu on tärkeää ja miten se voi vauhdittaa kaupunkien kehittämistä, mutta myös moniäänisyyttä ja yhteistä tarinaa. Jaksossa kuulet, miten taide ja luovuus voi viritellä tulevaisuuksien tutkimista ja tulevaisuustekoja ja luoda toivoa ja uskoa meille kaikille tässä haastavien globaalien kriisien ajassa ja maailmassa.`,
    },
    en: {
      title: "18 \\ Auri Antinranta and Minna Koskelo",
      intro: `TODO`, // TODO E18 english intro
    },
    homeImage: "/Episode18.png",
    previewDate: new Date("2024-11-29T04:00:00Z"),
    date: new Date("2024-12-03T04:00:00Z"),
    link: "/episode18",
    spotify: "",
  },
];

export const resources: Resource = {
  fi: {
    translation: {
      hero1: "Keskusteluja kaupunkimuotoilusta",
      hero2: "podcast",
      hero1_1: "Keskusteluja",
      hero1_2: "kaupunkimuotoilusta",
      hero_intro:
        "Digitalistin keskusteluja kaupunkimuotoilusta - podcastissa pureudutaan kaupunkimuotoiluun ja tuodaan esiin sen merkitystä erilaisten näkökulmien kautta. Pohdimme mielenkiintoisten vieraiden kanssa myös miksi kaupunkeja tulisi kehittää muotoilun menetelmin ja nostamme esiin erilaisia esimerkkejä kaupunkimuotoilun saralta.",
      listen: "Kuuntele jakso Spotifyssa",
      release: "TULOSSA \\",
      contact1: "Ota",
      contact2: "yhteyttä",
      music: "Podcast musiikki: Annika Anikari",
      about: "Tutustu Digitalistiin",
      contact_info: "yhteystiedot",
      privacy: "Tietosuoja",
      terms: "Käyttöehdot",
      cookie: "Evästeasetukset",
      copyright: "© {{year}} Digitalist Group. Kaikki oikeudet pidätetään.",
      back: "Takaisin",

      episode0_title: "0 \\ Tervetuloa keskustelemaan palvelumuotoilusta",
      episode0_paragraphs:
        "Tässä jaksossa juontajamme Päivi Vartiainen ja Saara-Sisko Jäämies johdattaa kuulijamme Keskusteluja kaupunkimuotoilusta-podcastin pariin ja kertoo lyhyesti mitä ensimmäiseltä kaudelta on odotettavissa.§§§" +
        "Saara-Sisko Jäämies työskentelee Digitalistilla Senior Service Designerina. Hän on aiemmin työskennellyt kaupunkiorganisaatiossa ja julkisen sektorin palveluiden parissa yli 15 vuoden ajan. Saara on palvelumuotoilun, yhteiskehittämisen ja fasilitoinnin intohimoinen ammattilainen. Saaran erityisenä kiinnostuksen kohteena on ihmisten arkisen elämän palveluiden ja kaupunkilaiskokemuksen kehittäminen. Palvelumuotoilijana hänelle on erityisen tärkeää muutoksen sosiaalisen luonteen vaaliminen. Saara uskookin, että vaikuttavat ja merkitykselliset kokemukset syntyy ihmisten ääntä ja tarpeita kuunnellen ja yhteiskehittäen, siksi muotoiluajattelun ja luovien yhteiskehittämisen menetelmien tärkein tehtävä on herättää keskustelua ja dialogia.§§§" +
        "Päivi Vartiainen työskentelee Digitalistilla Lead Insight Specialistina. Päiville on erityisen tärkeää saada käyttäjien ääni esiin muotoiluprojekteissa. Hän on tehnyt pitkän uran käyttäjätutkijana ja käytettävyysasiantuntijana ensin teollisuuden puolella ja viimeisen 7 vuoden ajan konsulttina monissa eri julkisen sektorin ja Helsingin kaupungin hankkeissa. Päiville on herännyt erityinen kiinnostus kaupunkien kokonaisvaltaisempaan ja systemaattisempaan kehittämiseen muotoilun keinoin. Päivi syttyy erityisesti keskusteluissa empatian lisäämisestä ja myös datan hyödyntämisestä, niin että olemassa olevaa tietoa pystyttäisiin hyödyntämään mahdollisimman tehokkaasti.§§§",
      episode0_quote: "",
      episode0_materials_heading: "Materiaalisuosituksia Saaralta ja Päiviltä:",
      episode0_caption_0:
        "Päivi Vartiainen<br/>Lead Insight Specialist, Service Designer<br/><br/>Saara-Sisko Jäämies<br/>Senior Service Designer",
      episode0_summary: "",

      episode1_title:
        "1 \\ Miksi kaupunkeja tulisi kehittää palvelumuotoilun keinoin?",
      episode1_paragraphs:
        "Tässä jaksossa vieraanamme on kaupunkimuotoilun pioneeri Sara Ikävalko, joka työskentelee Lahden LAB-ammattikorkeakoulussa kaupunki- ja palvelumuotoilun lehtorina. Hän oli 10 vuotta sitten ensimmäinen palvelumuotoilija joka palkattiin julkishallintoon Suomessa. Sittemmin kaupunkimuotoilu on saanut laajasti jalansijaa Suomessa. Sara oli mukana vastuuopettajana rakentamassa LABin palvelu- ja kaupunkimuotoilun koulutusta.§§§" +
        "Jaksossa keskustelemme Saran kanssa siitä, miten muotoilun keinoja tulee ja voidaan hyödyntää kaupunkien kehityksessä. Pohdimme myös mitä kaupunkimuotoilu on, ja jaamme muutamia konkreettisia esimerkkejä kaupunkimuotoilu-projekteista ja niiden todellisista vaikutuksista.§§§",
      episode1_quote:
        '"Haluaisin, että tulevaisuudessa jokaisella virkahenkilöllä ja toimihenkilöllä olisi mahdollisuus ja aikaa olla niiden ihmisten kanssa joiden elinympäristöä tai palveluympäristöä he kehittävät, ja joiden elämää koskevia päätöksiä he tekevät."',
      episode1_materials_heading: "Materiaalisuosituksia Saralta:",
      episode1_caption_0: "Sara Ikävalko<br/>Lehtori, LAB-ammattikorkeakoulu",

      episode2_title: "2 \\ Mitä kaupungit voisivat oppia liikemaailmasta?",
      episode2_paragraphs:
        "Tässä jaksossa vieraanamme on Johanna Partti, joka työskentelee tällä hetkellä Kaupunkiliikenne Oy:n strategiajohtajana. Hänellä on pitkä ura Finnairilla lähtien lentoemännästä Finnairin asiakaskokemuksen kehittäjäksi. Näiden keräämiensä oppien ja kokemusten pohjalta Johanna on nyt lähtenyt kehittämään asiakaskokemusta Kaupunkiliikenne Oy:ssa, jonka perustehtävä on tuottaa ja kehittää pääkaupunkiseudun kestävän liikkumisen palveluita ja infrastruktuuria laadukkaasti ja kustannustehokkaasti.§§§" +
        "Jaksossa keskustelemme Johannan kanssa siitä, mitä oppeja liikemaailmasta voidaan soveltaa kaupunkien kehityksessä. Jaksossa syvennytään tavoitteelliseen asiakaskokemuksen kehittämiseen ja pohditaan kuinka kaupunkien asiakaskokemusta voidaan kehittää tavoitteellisesti.§§§",
      episode2_quote:
        '"Kaupungin visio tulisi olla se millaista kokemusta kaupunki luo asukkaille. Kaupunkikokemusta voidaan positioida ja erottautua sillä. Jotta saavutetaan optimaalinen kaupunkilaiskokemus, se pitää tehdä yhdessä kaupunkilaisten kanssa."',
      episode2_materials_heading: "Materiaalisuosituksia Johannalta:",
      episode2_caption_0:
        "Johanna Partti<br/>Strategiapäällikkö, Kaupunkiliikenne Oy",

      episode3_title: "3 \\ Miksi empatia ja ihmiskeskeisyys on tärkeää?",
      episode3_paragraphs:
        "Tässä jaksossa vieraanamme on johtava palvelumuotoilija Anni Leppänen, joka työskentelee Helsingin kaupunginkansliassa. Anni johtaa kaupungin digitaalisen asiakaskokemuksen kehittämistä ja hän on perustanut julkisen sektorin muotoilijoiden Julkismuotoilijat-verkoston. Annilla on vankka kokemus empatian ja ihmiskeskeisten menetelmien hyödyntämisessä julkisen sektorien palveluiden kehittämisessä.§§§" +
        "Jakossa syvennymme Annin kanssa empatiaan ja ihmiskeskeisyyteen ja pohdimme miksi nämä ovat tärkeitä seikkoja myös kaupunkien muotoilussa. Jaksossa kuullaan myös mm. Konkreettinen esimerkki Hel.fi sivustouudistuksesta ja siitä kuinka sen puitteissa onnistuttiin ihmiskeskeisyyden ja empatian tuomisessa mukaan projektiin.§§§",
      episode3_quote:
        '"Demografinen segmentti tai kohderyhmäajattelu ei kerro riittävän rikkaasti ja moninaisesti ihmisistä, siksi tarvittiin rinnalle myös näitä muita työkaluja. Oli huikeaa nähdä miten tutkimusvaiheen kautta syntyneet persoonat ja tarinat toimi empatian kuljettajana."',
      episode3_materials_heading: "Materiaalisuosituksia Annilta:",
      episode3_caption_0:
        "Anni Leppänen<br/>Johtava palvelumuotoilija, Helsingin kaupunki",

      episode4_title: "4 \\ Miten huomioida heikoimmassa asemassa olevia?",
      episode4_paragraphs:
        "Tässä jaksossa vieraanamme on kaupunkiteologian professori Henrietta Grönlund Helsingin yliopistosta. Henrietta on keskittynyt tutkimuksissaan tarkastelemaan kansalaisyhteiskunnan organisaatioita ja niiden rooleja yhteiskunnassa sekä hyvän tekemistä, ja näihin teemoihin liittyviä arvoja.§§§" +
        "Jakson alussa avaamme kaupunkiteologian käsitettä ja sitä miten se liittyy kaupunkien muotoiluun. Tämän jälkeen keskustelemme siitä, kuinka kaupunkimuotoilussa usein koetaan haastavaksi kaupunkilaisten moniäänisyyden saaminen mukaan. Jaksossa kuullaan myös kuinka kaupunkilaisten halua osallistua ja vaikuttaa voidaan tukea, erityisesti niin että myös heikommassa asemassa olevat kaupunkilaiset tulevat huomioiduiksi.§§§",
      episode4_quote:
        '"Kansalaisjärjestöillä ja orgaanisesti syntyvillä yhteisöillä on iso rooli heikommassa asemassa olevien ihmisten saavuttamisessa. Ihmisten toimijuus tulee ottaa vakavasti, sillä siellä meillä on valtava resurssi käyttämättä."',
      episode4_materials_heading: "Materiaalisuosituksia Henrietalta:",
      episode4_caption_0:
        "Henrietta Grönlund<br/>Kaupunkiteologian professori, Helsingin Yliopistosto",

      episode5_title: "5 \\ Miten parantaa yhteistyötä muotoilun avulla?",
      episode5_paragraphs:
        "Tässä jaksossa vieraanamme on Helsingin kaupungin osallisuuspäällikkö Johanna Sinkkonen. Johanna tekee myös väitöskirjaa Tampereen yliopistossa kuntien osallisuuteen ja demokratiaan liittyen.§§§" +
        "Jaksossa pureudumme Johannan kanssa yhteiskehittämiseen ja yhteistyön parantamiseen kaupunkien muotoilussa. Keskitymme erityisesti siihen kuinka yhteistyötä voidaan parantaa kaupunkiorganisaation sisällä ja kuulemme esimerkkejä kaupunkien sisäisestä yhteiskehittämisestä.§§§",
      episode5_quote:
        '"Harvoin kaupungeissa olevat ilmiöt ovat sellaisia, että niitä voisi yksi professio tai organisaation osa ratkaista, vaan niihin pitääkin nivoa yhteen monenlaisia toimijoita. Se edellyttää sitä, että me sanoitetaan yhdessä se kysymys tai haaste, mitä me ollaan lähdössä ratkaisemaan."',
      episode5_materials_heading: "Materiaalisuosituksia Johannalta:",
      episode5_caption_0:
        "Johanna Sinkkonen<br/>Osallisuuspäällikkö, Helsingin kaupunki",

      episode6_title: "6 \\ Miten kehittää kestävästi muotoilun keinoin?",
      episode6_paragraphs:
        "Tässä jaksossa vieraanamme on tulevaisuudentutkija ja yrittäjä Aleksi Neuvonen. Aleksi on mm. yksi Demos Helsingin perustajista, ja on kirjoittanut väitöskirjan, jossa hän haastaa kuntia yhteiskunnallisten muutosvoimien ja niiden vaikutusten tunnistamiseen.§§§" +
        "Jakson teemana on kestävä kehitys ja keskustelemme Aleksin kanssa siitä, kuinka muotoilua tulisi hyödyntää kaupunkien kestävässä kehityksessä. Jaksossa Aleksi avaa myös backcasting-menetelmää, jota hän on tutkinut väitöskirjassaan ja kertoo miten se liittyy kaupunkien muotoiluun. Lopuksi pohditaan myös kuinka dataa voidaan hyödyntää kaupunkien muotoilussa.§§§",
      episode6_quote:
        '"Meillä voi olla ristiriitaiset toivetilat siitä, mihin yhteiskunta on menossa. Tulevaisuuskeskustelun ja ennakoinnin tavoitteena on luoda vaihtoehtoisia tulevaisuuksia, joissa erilaiset tavoitetilat on voitu saavuttaa. On tärkeää, että miten ihmiset voivat oivaltaa mihin suuntaan ollaan menossa ja mitä tän pitäisi olla tulevaisuudessa."',
      episode6_materials_heading: "Materiaalisuosituksia Aleksilta:",
      episode6_caption_0: "Aleksi Neuvonen<br/>Perustaja, Demos Helsinki",

      episode7_title: "7 \\ Miten muotoilu ja politiikka kohtaavat?",
      episode7_paragraphs:
        "Tässä jaksossa vieraanamme on Turun kaupungin apulaispormestari Elina Rantanen, jonka vastuualueena on elokuusta 2023 alkaen kaupunkiympäristö. Aiemmat kaksi vuotta hän toimi hyvinvoinnin apulaispormestarina. Elina toimii myös kaupunkiympäristölautakunnan puheenjohtajana sekä Turun kaupunginhallituksen 2. varapuheenjohtajana. Hän on viidennen kauden vihreiden kaupunginvaltuutettu ja ensimmäisen kauden aluevaltuutettu Varsinais-Suomen hyvinvointialueella. Elina on aiemmin työskennellyt erilaisissa tutkimus- ja kehitysprojekteissa Turun ja Helsingin yliopistoissa ja Sosiaali- ja terveysministeriössä, sekä toiminut kehitysjohtajana Kehitysvammaisten Tukiliiton Harvinaiskeskus Noriossa. Elinaa kiinnostaa erityisesti mm. ilmasto- ja luontokysymykset, eriarvoisuuden ehkäisy, yhdenvertaisuuden edistäminen sekä kaupunkikulttuuri.§§§" +
        "Jaksossa keskustelemme Elinan kanssa siitä, miten asukastietoa voidaan hyödyntää päätöksenteon tukena, sekä siitä kuinka hyvin kaupunkilaisten ääni käytännössä saadaan mukaan poliittiseen päätöksentekoon. Pohdimme myös minkälaisia vaikutuksia kaupunkimuotoilulla ja empaattisella asukastiedolla voisi olla tulevaisuuden kaupunkien päätöksenteossa.§§§",
      episode7_quote:
        '"Tietoa kertyy paljon kaupungin sisällä eri hankkeissa ja asukkaiden kanssa vuorovaikutuksessa joka päivä. Tavoitteena on tehdä tiedolla johdettua poliittista päätöksentekoa. Tiedon siivilöinti on kehittynyt paljon, mutta tieto ei ole välttämättä systemaattista tai yhdessä tietokannassa - se on esimerkiksi juuri siellä hankkeissa sisällä olevaa. Päättäjänä pitää uskaltaa ja osata myös kysyä sellaisen kokemustiedon perään."',
      episode7_materials_heading: "Materiaalisuosituksia Elinalta:",
      episode7_caption_0:
        "Elina Rantanen<br/>Apulaispormestari, Turun kaupunki",

      episode8_title:
        "8 \\ Kuuntelijan poiminnat: Mitä olen oppinut kaupunkimuotoilusta?",
      episode8_paragraphs:
        "Ensimmäisen kauden viimeisessä jaksossa keskustelemme kuuntelijamme Johanna Pikkaraisen kanssa menneestä kaudesta. Johanna työskentelee hyte-suunnittelijana Kirkkonummen kunnassa. Hyvinvoinnin ja terveyden edistämisen eli hyte-työ tarkoittaa toimia, joilla ylläpidetään kuntalaisten terveyttä, hyvinvointia, osallisuutta sekä työ- ja toimintakykyä ja vähennetään terveyseroja. Tehtävä kunnassa on laaja-alainen ja poikkihallinnollinen, sillä hyvinvointia edistäviä toimia tehdään kunnan kaikilla sektoreilla. Lisäksi hyvinvoinnin edistämistä tehdään yhteistyössä paikallisten ja alueellisten toimijoiden kanssa.§§§" +
        "Jaksossa pureudumme kauden varrelta esiin nousseisiin teemoihin kuuntelijan näkökulmasta ja pohdimme mitkä asiat kuluneelta kaudelta ovat erityisesti jääneet mieleen.§§§",
      episode8_quote:
        '"[podcastista] olen saanut oikeanlaista sanoitusta asioille ja olen pystynyt niitä käyttämään jo omassa työssä"',
      episode8_materials_heading: "Materiaalisuosituksia Johannalta:",
      episode8_caption_0: "Johanna Pikkarainen<br/>Kirkkonummen kunta",

      episode9_title: "9 \\ Mitä on Customer Journey Ops?",
      episode9_paragraphs:
        "Jaksossa keskustelemme customer journey opsista, joka on tällä hetkellä paljon puhututtava aihe palvelumuotoilun kentällä niin Suomessa kuin globaalisti. Pohdimme mitä CJO tarkoittaa, miten ja miksi sitä tehdään ja miksi siihen kannattaisi satsata myös julkisen sektorin organisaatioissa ja asiakaskokemuksen kehittämisessä.§§§" +
        "Mitä Customer Journey Ops tarkoittaa? Miten palvelupolkujohtamista tehdään kaupunkikontekstissa? Millaisia vaikutuksia palvelupolkujohtamisella on kaupunkikokemukseen?§§§",
      episode9_quote:
        '"Muodostuuko kaupungin palveluja käyttävälle asiakkaalle yhtenäinen kuva kaupungista? Organisaation siilomaisuus saattaa välittyä asiakaskokemukseen. Päällekkäinen tekeminen ja tehoton tekeminen on ikävä puoli, mitä tällä isommalla palvelupolkujohtamisella voitaisiin taklata."',
      episode9_materials_heading: "Materiaalisuosituksia Kaarinilta:",
      episode9_caption_0:
        "Kaarin Laaneots<br/>Palvelumuotoilija, Helsingin kaupunki",

      episode10_title: "10 \\ Mitä ekosysteemifasilitoinnilla saavutetaan?",
      episode10_paragraphs:
        "Tässä jaksossa vieraanamme on Saara Simonen. Saara on tehnyt pitkän uran erilaisissa yhteistyöhankkeissa julkisen sektorin organisaatioiden kanssa ja on ollut mukana kehittämässä monitoimijaisia toimintamalleja sekä ekosysteemiyhteistyönrakenteita. Tällä hetkellä Saara työskentelee asiakkuus- ja kehittämispäällikkönä Helsingin Diakonissalaitoksella.§§§" +
        "Saaran kanssa keskustelemme ekosysteemeistä ja ekosysteemifasilitoinnista. Usein kimuranttien ongelmien ratkaisemisessa ekosysteemimäinen toimintamalli on välttämätön. Jaksossa kuulet arjen esimerkkejä ekosysteemimäisestä toiminnasta ja vinkkejä miten ekosysteemien fasilitoinnissa kannattaa lähteä liikkeelle.§§§" +
        "Mitä on ekosysteemifasilitointi? Millaiset toimijat kuuluvat kaupunkien ekosysteemeihin? Millaisia onnistumisia ekosysteemifasilitoinnissa on koettu?§§§",
      episode10_quote:
        '"Tämä aika on sen tyyppinen, että haasteet joita kohdataan on monimutkaisempia ja se puskee meitä entistä enemmän tekemään asioita yhdessä. On hyvä että kokeillaan mitä ne motivaatiot siellä taustalla onkaan ja tunnistetaan sekä tuodaan eri toimijoiden vahvuuksia aktiivisesti yhteen.”',
      episode10_materials_heading: "Materiaalisuosituksia Saaralta:",
      episode10_caption_0: "Saara Simonen<br/>Helsingin Diakonissalaitos",

      episode11_title:
        "11 \\ Miten hyödyntää laadullista tutkimusta kaupunkimuotoilussa?",
      episode11_paragraphs:
        "Tässä jaksossa vieraina on kaksi tutkimuksen syväosaajaa meiltä Digitalistilta: Jaana Wikström ja Lotta Nivari-Lindström.§§§" +
        "Jaana Wikström on pitkän linjan tutkimusosaaja, joka hallitsee laajasti eri tutkimusmenetelmiä ja kvalitatiivinen tutkimus on ollut hänellä lähellä sydäntä uran alusta saakka.§§§" +
        "Lotta on tutkimustaustainen senior-palvelumuotoilija. Lotan erityisosaamista ovat asiakas- ja henkilöstökokemuksen tutkiminen ja kehittäminen muotoiluajattelua soveltaen. Lotalla on pitkä työhistoria määrällisen ja laadullisen tutkimuksen parista sekä valtava kiinnostus ihmisen mieleen, kokemuksiin ja emootioihin.§§§" +
        "Lotan ja Jaanan kanssa keskustelemme laadullisesta tutkimuksesta kaupunkimuotoilussa. Pohdimme miten tehdään hyviä tutkimuskysymyksiä, millainen merkitys ajalla on laadullisen tutkimuksen onnistumiseen ja miten tutkimus nivotaan kaupunkimuotoilun prosessiin. Nostamme esiin myös haasteita, joita laadullisen tutkimuksen matkalla voi kohdata.§§§",
      episode11_quote:
        '"Laadullisessa tutkimuksessa on tärkeää antaa tilaa myös yllättäville asioille eikä tehdä liian tiukkaa rajausta, mutta tavoite pysyy mielessä, jotta saadaan sellaisia tuloksia, mitä pystytään aidosti hyödyntämään"',
      episode11_materials_heading: "Materiaalisuosituksia Lotalta ja Jaanalta:",
      episode11_caption_0:
        "Lotta Nivari-Lindström & Jaana Wikström<br/>Digitalist",

      episode12_title:
        "12 \\ Miten tekoäly vaikuttaa kaupunkien kehittämiseen?",
      episode12_paragraphs:
        "Tässä jaksossa vieraanamme on Maria Suutari-Jääskö Järvenpään kaupungilta sekä yrittäjänä työskentelevä tila- ja palvelumuotoilija Inka Saini.§§§" +
        "Maria työskentelee Kaupunkikuva-arkkitehtina, kaupunkisuunnittelijana ja rakentamisen ohjaamisessa yleissuunnittelun tasolta asemakaavoitukseen, sekä on mukana kumppanuus kaavoituksissa ja ohjaa lausunnon antajana rakennuslupia. Tekoälyltä Maria odottaa  enemmän älyä ja apua suunnitteluun.§§§" +
        "Inka toimii monikanavaisten palveluympäristöjen muotoilijana, korostaen fyysisen ja digitaalisen maailman rajapintaa. Hän näkee tekoälyn ennemminkin kanssaluojana ja tärkeänä tukiälynä, joka ei ainakaan vielä tällä hetkellä poista päätöksentekoa ja vastuuta, joka ihmisillä on.  Viimeisen vuoden ajan Inka on toiminut päätoimisena tila-ja palvelumuotoiluyrittäjänä.§§§" +
        "Marian ja Inkan kanssa keskustelemme ja pohdiskelemme tekoälyn hyödyntämistä kaupunkien kehittämisessä ja kaupunkimuotoilussa. Keskustelemme erilaisista tekoälykokeiluista, haasteista ja onnistumisista sekä pohdimme millaisiin tilanteisiin tekoälyn käyttö sopii ja ei sovi ja mitkä ovat tekoälyn käytön eettiset puolet.§§§" +
        "Jakso on nauhoitettu toukokuussa 2024.",
      episode12_quote:
        '"Tekoäly on valtavan älykäs, mutta ei viisas. Viisauteen tarvitaan ihmistä ja tunneälykkyyttä. Ihmisillä on kuitenkin se asiantuntijuus, jota pitäisi kehittää tekoälyn ohella. Tekoäly ei kaikkeen pysty – toki ollaan vasta sen kehityksen alkuvaiheessa"',
      episode12_materials_heading: "Materiaalisuosituksia Marialta ja Inkalta:",
      episode12_caption_0: "Maria Suutari-Jääskö<br/>Kaupunkikuva-arkkitehti",
      episode12_caption_1: "Inka Saini<br/>Tila- ja palvelumuotoilija",

      episode13_title: "13 \\ Mitä huomioida kaupunkien innovaatiotoiminnassa?",
      episode13_paragraphs:
        "Tässä jaksossa vieraanamme on Tommi Laitio. Tommi on Los Angelesissa asuva, kansainvälisesti tunnustettu kaupunkielämän strategi, jonka projektit keskittyvät siihen, miten kaupunkielämän hankaumat muutetaan toimiviksi palveluiksi, paikoiksi ja työpaikoiksi. Vuosina 2017–2021 Tommi oli Helsingin ensimmäinen kulttuurin ja vapaa-ajan toimialajohtaja. Innovatiivisen, tuloksellisen ja osallistavan johtamisensa ansiosta Tommi kutsuttiin maailman ensimmäiseksi Bloomberg Public Innovation Fellow´ksi yhdysvaltalaiseen Johns Hopkinsin huippuyliopistoon, missä hän tutki kahden vuoden ajan julkisen tilan kehittämisen kumppanuusmalleja. Tommin tämänhetkinen työ vaihtelee pormestarien innovaatioajattelun sparrauksesta kirjastojen palvelukokemuksen kehittämiseen tai sen pohtimiseen, miten amerikkalaisessa kaupungissa voidaan taata jokaiselle lapselle mahdollisuus harrastukseen.§§§" +
        "Tommin kanssa keskustelemme kaupunkien kehittämisestä, muotoilusta ja innovaatiotoiminnasta ja niihin liittyvästä hankauksesta. Pohdimme miten kaupungit selviävät monimutkaisessa toimintaympäristössä ja sitä millaisia kyvykkyyksiä kehittäminen ja kehittämiskumppanuudet kaupungilta vaatii.",
      episode13_quote:
        '"Yksinkertaisimmillaan innovaatiotoiminnassa on kysymys siitä, että tehdään asioita toisella tavalla systemaattisesti - eli tehdään asioita eri tavalla kuin nyt jossakin asiassa, joka vaatii uusia ratkaisuja."',
      episode13_materials_heading: "Materiaalisuosituksia Tommilta:",
      episode13_caption_0: "Tommi Laitio",

      episode14_title:
        "14 \\ Miten asiakaskokemuksen kehittäminen vahvistaa demokratiaa?",
      episode14_paragraphs:
        "Tässä jaksossa vieraanamme on Elina Piskonen, joka tunnetaan viestinnän palvelumuotoilun pioneerina. Työssään Opetushallituksen asiakaskokemuspäällikkönä Elina muuttaa toimintatapoja, työprosesseja ja kulttuuria asiakaskeskeiseen suuntaan sekä kehittää kädet savessa laajojen digitaalisten järjestelmien asiakaskokemusta. Elinan kanssa keskustelemme asiakaskokemuksesta, viestinnästä ja palvelumuotoilusta ja siitä, miten näiden yhdistäminen voi vahvistaa demokratiaa ja kuulluksi tulemisen kokemuksia.",
      episode14_quote:
        '"On iso ero siinä kuullaanko vai kuunnellaanko. Ja se kokemus siinä osallistumisessa on silloin aivan eri. Paljon pitää tehdä töitä sen eteen, että on mahdollisuus kuunnella niissä oikeissa kohdissa, että ihmiset tietävät milloin on mahdollisuus osallistua, ja meillä myös mahdollisuus hyödyntää sitä tietoa, mitä sieltä tulee."',
      episode14_materials_heading: "Materiaalisuosituksia Elinalta:",
      episode14_caption_0:
        "Elina Piskonen<br/>Asiakaskokemuspäällikkö, Opetushallitus",

      episode15_title:
        "15 \\ Miten vahvistaa kaupunkien muotoilumaturiteettia?",
      episode15_paragraphs:
        "Tässä jaksossa vieraanamme on Tampereen kaupungilla työskentelevä palvelumuotoilija Katja Kumpulainen. Katja on työskennellyt lähes koko uransa erilaisissa kehittämistehtävissä ihmisten parissa. Eniten Katjaa innostaa se, kun saa laittaa kädet saveen ja kehittää palveluja yhdessä asiakkaiden ja sidosryhmien kanssa. Katjan kanssa keskustelemme muotoilumaturiteetista ja palvelumuotoilun asemasta erityisesti kaupunkikontekstissa. Jaksossa tutustutaan Katjan tekemään tutkimukseen ja pohditaan, millä keinoin muotoilun maturiteettia voidaan vahvistaa organisaatioiden sisäisissä prosesseissa ja madaltaa kynnystä muotoiluajatteluun työelämässä niin julkisella kuin yksityisellä sektorilla.",
      episode15_quote:
        '"Muotoilun maturiteetti kehittyy ajan kanssa ja siihen voi tulla uusia suuntauksia. Siinä ei ole päätepistettä ja muotoilusta oppii aina jotain uutta"',
      episode15_materials_heading: "Materiaalisuosituksia Katjalta:",
      episode15_caption_0: "Katja Kumpulainen<br/>Palvelumuotoilija",

      episode16_title: "16 \\ Miten edistää lapsinäkökulmaa muotoilun keinoin?",
      episode16_paragraphs:
        "Tässä jaksossa vieraanamme on lapsikeskeisen palvelumuotoilun uranuurtaja, palvelumuotoilija Maija Hansen, jonka kanssa keskustelemme lapsikeskeisestä palvelumuotoilusta ja palveluiden kehittämisestä lapsen silmin. Pohdimme mitä on lapsen palvelukokemus ja miten sitä voidaan tukea. Maija on työskennellyt sosiaali- ja terveysalalla, järjestöissä sekä julkisella sektorilla ja hänen erityisosaamisalaansa on yhteiskunnallinen muotoilu. Tällä hetkellä Maija työskentelee Helsingin kaupungilla vammaispalveluissa vammaisten lasten palvelupolkujen sujuvoittamisen parissa.",
      episode16_quote:
        "“Jos me katsellaan palveluita lasten silmin, niin abstraktille ja etäälle ne palvelut jää vaikka palvelut olisikin suoraan lapsille. Toivoisi jotenkin et se lapsen kokemusääni niistä palveluista kuuluisi paremmin. Kuka johtaa lapsen palvelukokemuksen muotoilua?”",
      episode16_materials_heading: "Materiaalisuosituksia Maijalta:",
      episode16_caption_0: "Maija Hansen<br/>Palvelumuotoilija",

      episode17_title:
        "17 \\ Miltä kaupunki näyttää nuoren muotoilijan silmin?",
      episode17_paragraphs:
        "Tässä jaksossa podcast-studiomme ovat ottaneet valtaansa kaupunkimuotoilua LAB-ammattikorkeakoulussa opiskelleet alumni Henna Partanen ja pian valmistuva Pyry Viitikko.§§§" +
        "Henna Partanen on suunnittelija, joka edistää sosiaalista kestävyyttä laadukkaiden ja inklusiivisten kaupunkitilojen ja palveluiden kautta. Opinnäytetyössään Kutsuvat kaupunkitilat – julkisen tilan ihmislähtöinen suunnitteluohjeisto, hän tutki kaupunkisuunnittelun haasteita ja muotoili ohjeiston viihtyisän sekä ihmisten moninaiset tarpeet huomioivan kaupunkitilan kehittämisen tueksi.§§§" +
        "Pyry Viitikko suorittaa viimeistä opiskeluvuottaan Muotoiluinstituutissa palvelumuotoilija-nimikkeen alla. Pyry on kiinnostunut ihmisten kokonaisvaltaisesta hyvinvoinnista kaupungeissa, sekä mahdollisuuksista eläväisen ja aktiivisen kaupunkiympäristön kehittämisessä.§§§" +
        "Pyry ja Henna keskustelevat kaupunkikokemuksesta eri käyttäjäryhmien näkökulmasta, jakaen havaintojaan sekä kokemuksiaan erilaisten kaupunkimuotoilun projektien parissa. He pohtivat miten pienillä kokeiluilla voidaan luoda eläväistä kaupunkitilaa, aktivoida kaupunkilaisia, tai lisätä kohtaamisen mahdollisuuksia ja yhteisöllisyyttä.",
      episode17_quote:
        "“Usein kuulee sanottavan, että kaupunki ei ole koskaan valmis ja että se on aina muutoksessa – ja sen ajatuksen voisi kääntää myös niin päin, että kaupunki ei ole muuttumaton vaan meidän on aina mahdollista suunnitella siitä tulevaisuudessa vielä parempi. Ja varsinkin kehittää meidän prosesseja paremmiksi huomioimaan asukkaat paremmin.”",
      episode17_materials_heading: "Materiaalisuosituksia Hennalta ja Pyryltä:",
      episode17_caption_0: "Henna Partanen<br/>Kaupunkimuotoilija",
      episode17_caption_1: "Pyyry Viitikko<br/>Palvelumuotoiluopiskelija",

      episode18_title:
        "18 \\ Miten taide, luovuus ja muotoilu kohtaavat tulevaisuuksien kaupunkien muotoilussa?",
      episode18_paragraphs:
        "Tässä jaksossa vieraanamme on Minna Koskelo ja Auri Antinranta.§§§" +
        "Minna Koskelo on tulevaisuusaktivisti, tietokirjailija ja tulevaisuusmuotoilun uranuurtaja Suomessa. Minna on myös strategista ennakointia edistävän Futures Finland ry:n perustaja ja hallituksen puheenjohtaja. Minna on työskennellyt tulevaisuuden kaupunkien kehittämisen parissa ja edistänyt aktiivisesti tulevaisuusajattelun leviämistä Suomessa. Auri Antinranta on ympäristöalaan erikoistunut tulevaisuustaiteilija, joka rakentaa tulevaisuusprototyyppejä musiikin ja äänen keinoin ja yhdistää tutkittua tietoa ja mielikuvittelua. Auri on työskennellyt pitkään muotoilijana ja ennakoinnin asiantuntijana monipuolisten projektien parissa sekä yksityisellä että julkisella sektorilla. Minna ja Auri ovat myös Tulevaisuuspäivä ryn ja kansallisen tulevaisuuspäivän perustajia. Vuosittainen Tulevaisuuspäivä- edistää tulevaisuustietoisuutta ja on suunnattu kaikille: kansalaisille, yrityksille ja kunnille. Vuoden 2025 tulevaisuuspäivän teemana on tuhat ja yksi tulevaisuusprototyyppiä.§§§" +
        "Aurin ja Minnan kanssa keskustelemme tulevaisuusajattelusta ja tulevaisuusmuotoilusta. Pohdimme miksi tulevaisuusajattelu on tärkeää ja miten se voi vauhdittaa kaupunkien kehittämistä, mutta myös moniäänisyyttä ja yhteistä tarinaa. Tulevaisuutta voidaan tehdä koettavaksi erilaiosten tulevaisuusprototyyppien avulla ja jaksossa kuulet, miten taide ja luovuus voi viritellä tulevaisuuksien tutkimista ja tulevaisuustekoja ja luoda toivoa ja uskoa meille kaikille tässä haastavien globaalien kriisien ajassa ja maailmassa.",
      episode18_quote:
        "“Tulevaisuusajattelu lähtee oman ajattelun ajattelemisesta. Meillä on rajoittunut kyky nähdä tulevaisuuksia ellei me herätellä itseämme siihen. Lähdetään siitä, että mitkä ne on ne tulevaisuusoletukset, ja tunnistetaan ne näkymättömät asiat, jotka vaikuttavat omaan ajatteluun ja sitten lähdetään pohtimaan sitä, voisko niitä lähteä haastamaan. Tässä yhtenä mahdollistajana on esimerkiksi, taide, jonka avulla voidaan luoda ihan uudenlaista kokemuksellisuutta ja ruokkia ajatuksia vaihtoehtoisista tulevaisuuksista, joita vaikka raportti ei voisi tehdä. Taide voidaan valjastaa fasilitoivaan rooliin ja sen avulla tulevaisuus tulee tässä hetkessä koettavaksi. Taide voi tuoda räväkän ja irrotelevan tason, toimia eräänlaisen ahäiriögeneraattorina.”",
      episode18_materials_heading: "Materiaalisuosituksia Minnalta ja Aurilta:",
      episode18_caption_0:
        "Auri Antinranta<br/>Tulevaisuustaiteilija, muotoilija",
      episode18_caption_1: "Minna Koskelo<br/>Tulevaisuusmuotoilija",
    },
  },
  en: {
    translation: {
      hero1: "City design - podcast",
      hero2: "podcast",
      hero1_1: "City design",
      hero1_2: "podcast",
      hero_intro:
        "In the Digitalist city design podcast, we talk about city design and bring out its importance through different perspectives. Together with interesting guests, we will also consider why cities should be developed using design methods and highlight various examples from the field of cityn design. The podcast can be listened to in Finnish on Spotify, and here you can find short summaries about the episodes in English.",
      listen: "Listen on Spotify",
      release: "RELEASE \\",
      contact1: "Contact",
      contact2: "Us",
      music: "Podcast music: Annika Anikari",
      about: "About Digitalist",
      contact_info: "contacts",
      privacy: "Privacy policy",
      terms: "Terms of use",
      cookie: "Cookie Preferences",
      copyright: "© {{year}} Digitalist Group. All Rights Reserved.",
      back: "Back",

      episode0_title: "0 \\ Welcome to discuss city design",
      episode0_paragraphs:
        "In this episode, our hosts Päivi Vartiainen and Saara-Sisko Jäämies lead our listeners to the city design podcast and briefly tell what to expect from the first season.§§§" +
        "Saara-Sisko Jäämies works at Digitalist as Senior Service Designer. She has previously worked in a city organisation and with public sector services for over 15 years. Saara is a passionate professional in service design, co-development and facilitation. Her special interest is the development of services for people's everyday life and the citisen experience. As a service designer, nurturing the social nature of change is particularly important to her. Saara believes that effective and meaningful experiences are created by listening to people and their needs and co-developing, therefore the most important task of design thinking and creative co-development methods is to stimulate discussion and dialogue.§§§" +
        "Päivi Vartiainen works at Digitalist as a Lead Insight Specialist. For Päivi, it is especially important to bring out the voice of the users in design projects. She has had a long career as a user researcher and usability expert, first in industry, and for the last 7 years as a consultant in many different projects of the public sector and the City of Helsinki. Päivi has developed a special interest in the more comprehensive and systematic development of cities through the means of design. Päivi especially gets fired up in discussions about increasing empathy and also utilising data, so that existing information can be utilised as efficiently as possible.§§§",
      episode0_quote: "",
      episode0_materials_heading:
        "Material recommendations by Saara and Päivi:",
      episode0_caption_0:
        "Päivi Vartiainen<br/>Lead Insight Specialist, Service Designer<br/><br/>Saara-Sisko Jäämies<br/>Senior Service Designer",
      episode0_summmary: "",

      episode1_title:
        "1 \\ Why should design methods be used in city development?",
      episode1_paragraphs:
        "In this episode, our guest is urban design pioneer Sara Ikävalko, who works at Lahti LAB University of Applied Sciences as a city and service design lecturer. 10 years ago, she was the first service designer hired by the public administration in Finland. Since then, urban design has gained a wide foothold in Finland. Sara was involved as a responsible teacher in building LAB's service and urban design education.§§§" +
        "In this first episode, we discuss with Sara how design methods can be used in the development of cities. We also consider what city design is, and share some concrete examples of city design projects and their real impacts.§§§",
      episode1_quote:
        '"I wish that in the future, every official and white-collar worker would have the opportunity and time to be with the people whose living or service environment they develop, and whose life the decisions they make touch upon."',
      episode1_materials_heading: "Material recommendations by Sara:",
      episode1_caption_0:
        "Sara Ikävalko<br/>Lecturer, LAB University of Applied Sciences",
      episode1_summary:
        "The first episode of the podcast delves deeply into the intricacies of city design and community engagement. According to Sara Ikävalko, the adoption of design methodology in developing cities brings the citizens to the center of the work while at the same time allowing for a wider perspective. Sara emphasises the importance of adopting a human-oriented approach when designing cities and spending more time with citizens in their natural living environments to better understand their experiences and needs. This on-the-ground interaction, she argues, fosters a deeper sense of empathy and understanding, making public administration more effective.\n" +
        "In the podcast, the role of involving marginalised or less outspoken groups, such as children and young people, in the city design process is highlighted. By applying design methodology to development processes, it’s possible to bring these people into the processes. One example mentioned is a neighbourhood program in Mukkula, Lahti, where young people have been actively participating in the development of their community for over three years. The program has been successful in empowering these young citizens, making them realise they too are essential stakeholders in shaping the future of their community.\n" +
        "The discussion with Sara Ikävalko then shifts to the financial constraints and challenges that cities face. She notes that with increasing service demands and a precarious financial situation, traditional models of governance and city planning may no longer be viable or sustainable. Adopting new, human-oriented development strategies and methodologies could not only make the process more effective but also more financially sustainable.\n" +
        "Another key point made in the podcast is the need for a radical shift in thinking and operations within city organisations. Sara Ikävalko encourages city officials to be brave in adopting new methods and breaking down age-old silos that often hinder comprehensive planning and implementation. City Design involves collaboration and co-creation across different departments and allows leveraging the expertise of various professionals in the field.\n" +
        "Towards the end of the podcast, Sara shares her wish for city organisations to be more daring in embracing multi-voiced, multi-perspective approaches to development. By doing so, she believes that the city planning process could become more inclusive, efficient, and responsive to the actual needs of the community.\n" +
        "In summary, the podcast serves as a call to action for city planners and officials to rethink traditional methods, adopt a more human-oriented approach, and involve a wider range of voices in decision-making. This, Sara Ikävalko believes, will lead to more empathetic, inclusive, and financially sustainable cities.\n",

      episode2_title: "2 \\ What could cities learn from the business world?",
      episode2_paragraphs:
        "In this episode, our guest is Johanna Partti, who currently works as the strategy director of Kaupunkiliikenne Oy. She has a long career at Finnair, startingoff as a flight attendant to developing Finnair's customer experience. Based on these lessons and experiences she has gathered, Johanna has now set out to develop the customer experience at Kaupunkiliikenne Oy, whose basic task is to produce and develop sustainable mobility services and infrastructure in the capital region in a high-quality and cost-effective manner.§§§" +
        "In the episode, we discuss with Johanna what lessons from the business world can be applied in the development of cities. We also delve into goal-oriented customer experience development and consider how this goal orientation can be applied to developing customer experience in cities.§§§",
      episode2_quote:
        '"The vision of a city should be what kind of experience the city creates for its residents. The city experience can be positioned and distinguished by that vision. In order to achieve an optimal city experience, it must be developed together with the residents."',
      episode2_materials_heading: "Material recommendations by Johanna:",
      episode2_caption_0:
        "Johanna Partti<br/>Strategy Director, Kaupunkiliikenne Oy",
      episode2_summary:
        "In this episode of the City Design podcast the customer experience of cities is discussed together with Johanna Partti from Kaupunkiliikenne. She stresses that cities are no different from other service providers and that it is always a matter of experience, whether you think about being a customer of an airline or being a citizen. Cities should learn from the business world to develop their customer experience towards a set goal, a vision of what kind of experience they want to produce. This vision will then act as a frame of reference when designing the city and its services. A well-defined vision can even become a competitive advantage for the city. When you have an understanding of what you want to provide citizens and visitors, you can also start to look at other cities and see where you stand compared to them.\n" +
        "In the podcast, Johanna emphasises that involving end users in the planning and development processes leads to better outcomes and reduces resistance also in the city design context. It is important to understand the needs and desires of the target groups within a city to create a more subjective and personalised experience for its inhabitants. Since the experience is also highly dependent on other customers, or in this case, citizens, it is also important to understand the differences for example between different areas within the city and adapt city design accordingly.\n" +
        "Feedback from customers is always valuable, even if it is not always positive, as it helps in goal-oriented development. Involving citizens in the development process is seen as crucial, as it increases understanding and ownership of the city. Johanna suggests for example conducting surveys and utilising online communities to include city residents in the planning of services. According to her, there might often be some reluctance to even ask customers or citizens anything, since the initial feeling is that nobody is willing to answer. This however has always proven to be wrong and people seem to be very eager to be able to give their five cents to things that are touching their everyday life.\n" +
        "In the city design context Johanna also calls for more of an experimentation culture. City development is a long-term process, often taking several years or even decades and it’s worth keeping in mind that many things can be tested and piloted along the way to make sure that the results are beneficial for both the city and the citizens. Testing and doing different light experiments helps in making sensible investments and using tax money in a way that aligns with the users' needs and is relevant to them.\n",

      episode3_title: "3 \\ Why is empathy and being human-centred important?",
      episode3_paragraphs:
        "In this episode, we meet leading service designer Anni Leppänen, who works at the City of Helsinki. Anni leads the development of the city's digital customer experience and she is the founder of the Public Designers network, a network for public sector designers. Anni has solid experience in utilising empathy and human-centred methods in the development of public sector services.§§§" +
        "In the episode, we delve deeper into empathy and people-centredness and we think about why these are also important aspects in the design of cities. The episode also includes e.g. a concrete example of using empathy during the Hel.fi website renewal.§§§",
      episode3_quote:
        '"A demographic segment or thinking about target groups doesn´t tell us enough about people in a rich and diverse way, that´s why these other tools were also needed. It was amazing to see how the personalities and stories created through the research phase acted as a driver of empathy."',
      episode3_materials_heading: "Material recommendations by Anni:",
      episode3_caption_0:
        "Anni Leppänen<br/>Lead Service Designer, City of Helsinki",
      episode3_summary:
        "Understanding the daily lives of citisens is crucial when developing services for them. Anni Leppänen emphasizes the importance of comprehensively understanding the diverse experiences of citisens and cultivating empathy towards them. One effective method she suggests is creating personas. Additionally, collaboration and information sharing among the city's various operators are crucial when developing the city's services. By employing design methods, these different parties can come together and create services that prioritize the needs of the city's residents.\n" +
        "Anni also introduces the concept of theme thinking. It recognizes that service paths are varied, and citisens encounter multiple city actors while utilizing a service. Anni believes that services should be considered within different themes. For example, in Helsinki, theme profiles were developed to understand how people use the city's website and how it integrates into their lives. These theme-based personas focus on specific themes, addressing the issues and behaviors related to them. In contrast, general customer personas provide a holistic view of a person as a citizen, independent of the services they use.\n" +
        "Looking ahead, Anni hopes to involve more city employees in interviewing, observing, and conducting customer research. By immersing themselves in the world of the customers, service developers can build empathy towards citisens, leading to enhanced services for users. According to her experience people value these real life encounters and they feel that they get completely new perspective to their work.\n",

      episode4_title:
        "4 \\ How to consider those who are in a weaker position?",
      episode4_paragraphs:
        "In this episode, we meet urban theology professor Henrietta Grönlund from the University of Helsinki. In her research, Henrietta has focused on examining civil society organisations and their roles in society, as well as doing good, and the values related to these themes.§§§" +
        "At the beginning of the episode, we open up the concept of urban theology and how it is related to the design of cities. After that, we discuss how it is often perceived as challenging to involve the multiple voices of the citizens in city design. In the episode, we will also hear how the desire of the citizens to participate and influence can be supported so that those in the weakest position are also taken into account.§§§",
      episode4_quote:
        '"Non-governmental organisations and organically formed communities play a big role in reaching people in a weaker position. People´s agency should be taken seriously because we have a huge unused resource there."',
      episode4_materials_heading: "Material recommendations by Henrietta:",
      episode4_caption_0:
        "Henrietta Grönlund<br/>Professor, Urban Theology, University of Helsinki",
      episode4_summary:
        "In this podcast episode, the concept of urban theology is explored. Herietta Grönlund explains that the concept examines the intersection of urban environments, religion and values. She stresses the importance of civic engagement and the role of civil society in urban planning and service development.\n" +
        "Henrietta emphasizes that urban development must be driven by the needs and aspirations of all citisens, in particular those in vulnerable or minority communities who often experience exclusion from decision-making processes. To facilitate their involvement, she suggests lowering the participation threshold and building trust through sustained engagement and investment of time and resources.\n" +
        "Furthermore, Henrietta highlights the essential role of civil society organisations in fostering community spirit and wellbeing, particularly in meeting fundamental needs such as food, security, and human relationships. She urges public actors to recognise civic activity as a valuable resource and to enable inclusion in urban planning processes on a long-term basis, rather than on a project-by-project basis.\n" +
        "To achieve these objectives, Henrietta emphasises the need for city stakeholders to critically examine their own prejudices and genuinely strive to understand the needs of different population groups. By prioritising trust-building and inclusion in urban planning and development, cities can become more equitable and sustainable in achieving healthy, thriving communities.\n",

      episode5_title: "5 \\ How to better develop cities together?",
      episode5_paragraphs:
        "In this episode, we meet Johanna Sinkkonen, the participation manager of the City of Helsinki. Johanna is also writing her doctoral thesis at the University of Tampere related to municipal participation and democracy.§§§" +
        "In the episode, we talk with Johanna about co-development and improving cooperation in the design of cities. We focus especially on how cooperation can be improved within the city organisation.§§§",
      episode5_quote:
        '"There are rarely such phenomena in cities that could be solved by one profession or part of the organisation, but they must involve a wide variety of actors. It requires that we together articulate the question or challenge that we are about to solve."',
      episode5_materials_heading: "Material recommendations by Johanna:",
      episode5_caption_0:
        "Johanna Sinkkonen<br/>Participation Manager, City of Helsinki",
      episode5_summary:
        "The podcast episode delves into the possibilities of digitisation and urban design in the participation of city residents and the development of the city. The discussion revolves around the need to listen to citizens and how cities must be open to multiple voices. In the episode, Johanna Sinkkonen highlights the importance of low-threshold participation and utilising the expertise and experience of the city's residents in the development of the city.\n" +
        "Successful urban design and co-development projects in Helsinki are also discussed. The goal in Helsinki is to create a culture of joint problem-solving where residents and different actors in the public sector can broaden their vision of their work to a wider whole. Internal co-development is reflected externally to the residents, increasing their commitment and legitimacy to the decisions made.\n" +
        "Johanna details some practical action items that cities can take to involve residents more widely, such as offering participation opportunities at an earlier stage throughout the entire process. She also mentions the need to experiment with joint development and increase resources for resident cooperation while lowering the threshold to participate in development.\n" +
        "For those looking to increase their understanding of urban design and co-development, Johanna suggests holding an urban design workshop for the city's management and key personnel. She also recommends assembling a pilot group of city residents and employees to come up with a new service concept together.\n" +
        "In summary, with the help of urban design and co-development, cities can find new and innovative solutions for the development of the city and improve engagement from their residents. In the podcast episode, Johanna provides inspiring and professional insights for cities looking to improve their approach to urban design and co-development.\n",

      episode6_title: "6 \\ How to develop sustainably through design?",
      episode6_paragraphs:
        "In this episode, our guest is futurist and entrepreneur Aleksi Neuvonen. Aleksi is one of the founders of Demos Helsinki and has written a dissertation in which he challenges municipalities to identify social change forces and their effects.§§§" +
        "In the episode, we discuss how design should be used in the sustainable development of cities. We will also hear how the backcasting method is related to city design, and how data can be utilised in designing cities.§§§",
      episode6_quote:
        '"We can have conflicting wishes about the direction where our society is going. The goal of discussing the future and forecasting is to create alternative futures in which different target states have been achieved. It is important that people can understand the direction we are going and what it should be in the future."',
      episode6_materials_heading: "Material recommendations by Aleksi:",
      episode6_caption_0:
        "Aleksi Neuvonen<br/>Futurist & Entrepreneur, Demos Helsinki",
      episode6_summary:
        "In this episode, Aleksi Neuvonen delves into the transformative role of design in shaping the future of cities, emphasising its ability to infuse a human perspective into urban planning. Aleksi highlights the significance of citizen involvement as a catalyst for city development, showcasing how people's initiatives, events, and sense of ownership contribute significantly to the urban environment. He also shares some successful examples of urban development through design methods, such as collaborative visioning, backcasting, and leveraging megatrends when creating city strategies.\n" +
        "In the episode, Aleksi underscores the importance of future forecasting and scenario planning in city development, promoting a shared vision and commitment to a desired direction. The fusion of qualitative and quantitative data emerges as a key strategy for understanding development trends, gauging public sentiment, and informing intelligent decision-making. The discussion advocates for a holistic approach, emphasising the need for diverse actors to engage in collective envisioning of the city's future, fostering understanding and collaboration.\n" +
        "Addressing the question of sustainable urban development through design, Aleksi suggests initiatives to involve citizens more broadly, including joint workshops and discussion events that encourage diverse perspectives and solutions. He encourages the collection and versatile use of qualitative and quantitative information to enhance decision-making processes. Additionally, the experimentation with small-scale design projects as part of city service development demonstrates a commitment to practical, hands-on approaches in achieving a living, sustainable, and people-oriented city. Overall, Aleksi highlights the power of design in shaping urban landscapes that are not only aesthetically pleasing but also socially vibrant and environmentally sustainable.\n",

      episode7_title: "7 \\ How do design and politics meet?",
      episode7_paragraphs:
        "In this episode, we meet the deputy mayor of the city of Turku, Elina Rantanen. From August 2023 onwards her area of responsibility is the urban environment. For the previous two years, she served as deputy mayor for welfare. Elina also serves as the chairman of the city environmental committee and as the 2nd vice-chairman of the Turku City Council. She is a fifth-term city councillor for The Greens party and a first-term regional councilor in the wellbeing services county of Southwest Finland. Elina has previously worked in various research and development projects at the universities of Turku and Helsinki and the Ministry of Social Affairs and Health. She has also worked as the development director of the Norio Centre of Rare Diseases which is hosted by Inclusion Finland, KVTL. Elina is particularly interested in e.g. climate and nature issues, prevention of inequality, promotion of equality and urban culture.§§§" +
        "In the episode, we talk about how resident information can be used to support decision-making. We also consider what kind of effects urban design and empathic population information could have on decision-making in future cities.§§§",
      episode7_quote:
        '"A lot of information is accumulated within the city in various projects and in interactions with residents every day. The goal is to make informed political decision-making. Information screening has developed a lot, but the information might not be systematic or in one database - it lies for example in various projects. As a decision maker, you have to have the know-how and also dare to ask for such experience information."',
      episode7_materials_heading: "Material recommendations by Elina:",
      episode7_caption_0: "Elina Rantanen<br/>Deputy Mayor, City of Turku",
      episode7_summary:
        "In this podcast episode, we discuss utilising design methods in city development with Turku city deputy mayor and green city councillor Elina Rantanen, who talks about her experiences as a decision-maker in urban planning. Elina says that she got involved in city development through political decision-making. She has been a Turku city councillor for 19 years. Cities interest her as structures and as stages of phenomena. The position of deputy mayor has opened up the matter in a new way when more time is available. Rantanen feels it is important to represent the citizens and to bring decisions from their perspective.\n" +
        "According to Elina, resident information is important to support political decision-making. In Turku, there is cooperation with the university in the field of urban research and information is collected in many ways, but according to Elina, the challenge is to get it more systematically to support political decision-making. The mayor's model has brought time to listen to residents, but it is especially important to acquire balanced information, not just listen to the loudest opinions.\n" +
        "The episode also discusses the role of citizens in the development of cities and states that the citizen is not just a passive recipient of services, but a part of the city. Citizens can influence in many ways, also voluntarily, e.g. by organizing events. The role of the city can be facilitative or directive. Elina emphasizes that the experience of participation is important for citizens, even if not all individual wishes can be fulfilled.\n" +
        "Among the challenges of urban development, Elina mentions, for example, the dismantling of segregated development, where decisions often arouse opposition. With the help of empathetic resident information, Elina thinks that solutions could be found even for difficult things. The key is interaction and a common vision that we are building a common city. Design enables empathetic planning and interaction, which according to Elina leads to better decision-making. She emphasizes that understanding the residents' experiences is important. Citizens should not be seen only as data reserves, but as living people for whom design helps create a smoother everyday life.\n" +
        "In the future, in Elina's opinion, the possibilities for a more systematic collection of empathetic resident information should be explored as part of urban planning. We have to make sure that, as a basis for urban planning, diverse information is gathered from different groups of residents, not just the loudest ones. In addition, in her opinion, investment should be made in more interactive planning and decision-making, where the citizens are genuinely heard. According to Elina, the independent activities and community spirit of the citizens should also be promoted to enrich the urban culture, and design methods, such as joint planning, empathic planning and user orientation in urban planning, should be utilized.\n",

      episode8_title:
        "8 \\ Listener takeaways: What have I learned about urban design?",
      episode8_paragraphs:
        "In the last episode of the first season, we discuss the past season with our listener Johanna Pikkarainen. Johanna works as a Planner of Wellbeing and Health Promotion in Kirkkonummi municipality. The work of promoting well-being and health, or hyte, means actions that maintain the health, well-being, inclusion, work and functional capacity of the municipal residents and reduce health differences. The task in the municipality is wide-ranging and cross-administrative, as welfare-promoting activities are carried out in all sectors of the municipality. In addition, welfare promotion is done in cooperation with local and regional actors.§§§" +
        "In the episode, we discuss the themes that emerged during the season from the listener's point of view, and we consider which things from the past season have particularly stuck in our minds.§§§",
      episode8_quote:
        '"[from the podcast] I have been able to find the right wordings for things and I have already been able to use them in my own work."',
      episode8_materials_heading: "Material recommendations by Johanna:",
      episode8_caption_0:
        "Johanna Pikkarainen<br/>Health- and Wellbeing Designer, Municipality of Kirkkonummi",
      episode8_summary:
        "In the last episode of the season, we meet our listener Johanna Pikkarainen, who works in welfare and health promotion in Kirkkonummi municipality. She has listened with great interest to the episodes of Digitalist's City Design podcast and has gotten a lot of ideas for her work from them. Johanna started her career in Sweden, where she gained experience in co-development and multi-professional cooperation. That's when she understood the importance of a smooth customer experience, and that has been a main theme for her ever since. She also feels that a more open discussion culture is very important.\n" +
        "Johanna especially emphasises the importance of interaction, joint development and customer understanding in city design. In her opinion, doing quick experiments is also useful. In smaller municipalities, resources may be limited for larger projects, but even small things can promote the utilization of urban design.\n" +
        "In Johanna's opinion, it is important to identify and harness the resources of the city or municipality and build interaction between the municipality and the residents. With the help of city design, residents' participation and thus well-being can be increased. City design can also create a new kind of cooperation between different actors. Johanna also emphasises that a common discussion is needed to find a common way of working.\n",

      episode9_title: "9 \\ What is Customer Journey Ops?",
      episode9_paragraphs:
        "In this episode, we discuss customer journey ops, which is currently a much-discussed topic in the field of service design in Finland and globally. We consider what CJO means, how and why it is done, and why it would be worth investing in it also in public sector organisations and in the development of customer experience.§§§",
      episode9_quote: "",
      episode9_materials_heading: "Material recommendations by Kaarin:",
      episode9_caption_0:
        "Kaarin Laaneots<br/>Service Designer, City of Helsinki",
      episode9_summary: "",

      episode10_title: "10 \\ What is achieved with ecosystem facilitation?",
      episode10_paragraphs:
        "In this episode, our guest is Saara Simonen. Saara has had a long career in various collaborative projects with public sector organizations and has been involved in developing multi-actor operating models and ecosystem collaboration structures. Currently, Saara works as a customer relations and development manager at the Deaconess Foundation.§§§" +
        "With Saara we discuss ecosystems and ecosystem facilitation. Often, in solving complex problems, an ecosystem-like approach is necessary. In the episode, you will hear everyday examples of ecosystem-like activity and tips on how to get started in facilitating ecosystems.§§§" +
        "What is ecosystem facilitation? What kind of actors belong to urban ecosystems? What kind of successes have been experienced in ecosystem facilitation?",
      episode10_quote: "",
      episode10_materials_heading: "Material recommendations by Saara:",
      episode10_caption_0: "Saara Simonen<br/>Deaconess Foundation",
      episode10_summary: "",

      episode11_title:
        "11 \\ How can qualitative research be leveraged in city design?",
      episode11_paragraphs:
        "In this episode, we have two deep research experts from Digitalist as our guests: Jaana Wikström and Lotta Nivari-Lindström.§§§" +
        "Jaana Wikström is a seasoned research expert who has a broad mastery of various research methods, and qualitative research has been close to her heart since the beginning of her career.§§§" +
        "Lotta is a senior service designer with research-background. Lotta's special expertise lies in researching and developing customer and employee experiences by applying design thinking. Lotta has a long work history with both quantitative and qualitative research and a vast interest in the human mind, experiences, and emotions.§§§" +
        "With Lotta and Jaana, we discuss the role of qualitative research in city design. We contemplate how to create good research questions, the significance of time in the success of qualitative research, and how research is integrated into the city design process. We also highlight challenges that may be encountered along the way of qualitative research.§§§",
      episode11_quote:
        '"In qualitative research, it\'s crucial to leave space for unexpected findings without imposing overly strict limits. However, keeping the objective in focus ensures that the results we achieve are truly valuable and can be effectively utilized."',
      episode11_materials_heading:
        "Material recommendations by Lotta and Jaana:",
      episode11_caption_0:
        "Lotta Nivari Lindström & Jaana Wikström<br/>Digitalist",
      episode11_summary:
        "In this episode we discuss the role of qualitative research in city design. We contemplate how to create good research questions, the significance of time in the success of qualitative research, and how research is integrated into the city design process.",

      episode12_title: "12 \\ How Does AI Impact City Design?",
      episode12_paragraphs:
        "In this episode, our guests are Maria Suutari-Jääskö from the City of Järvenpää and Inka Saini, who works as an service design entrepreneur.§§§" +
        "Maria works as an urban architect and planner, and oversees urban development and construction from the level of general planning to detailed town planning.§§§" +
        "Inka works as a designer of multichannel service environments, emphasizing the interface between the physical and digital worlds.§§§" +
        "With Maria and Inka, we discuss and reflect on the utilization of artificial intelligence in city development and city design. We talk about various AI experiments, challenges, and successes, and contemplate in which situations the use of AI is appropriate or not, as well as the ethical aspects of using AI.§§§" +
        "The episode was recorded in May 2024.",
      episode12_quote: `"Artificial Intelligence is incredibly smart, yet it lacks wisdom. Wisdom necessitates human presence and emotional intelligence. Nevertheless, it is the expertise of humans that needs to be advanced in tandem with AI. AI isn't capable of everything – it's important to remember we're still in the early stages of developing it."`,
      episode12_materials_heading:
        "Material recommendations by Maria and Inka:",
      episode12_caption_0:
        "Maria Suutari-Jääskö<br/>Architect and Urban planner",
      episode12_caption_1: "Inka Saini<br/>Spatial Service Designer",
      episode12_summary: "",

      episode13_title:
        "13 \\ What are the key considerations for Innovation in City Development?",
      episode13_paragraphs:
        "Our guest for this episode is Tommi Laitio. Tommi is a globally recognized urban life strategist based in Los Angeles, whose projects focus on transforming urban life frictions into functional services, places, and workplaces. From 2017 to 2021, Tommi served as Helsinki's first Executive Director for Culture and Leisure. Due to his innovative, effective, and participatory leadership, Tommi was invited to become the world's first Bloomberg Public Innovation Fellow at the prestigious Johns Hopkins University in the United States, where he spent two years researching partnership models for public space development. Tommi's current work ranges from mentoring mayors on innovation thinking to developing the service experience in libraries.§§§" +
        "In this episode, we explore the challenges and complexities of City design, and innovation. We'll discuss the friction that arises in transforming cities, the crucial skills needed, and the partnerships that make it possible. Join us as we dive into how cities navigate their complex environments to foster innovation and growth.",
      episode13_quote:
        '"At its simplest, innovation activity is about systematically doing things differently - that is, doing things in a new way compared to the current approach in something that requires new solutions.”',
      episode13_materials_heading: "Material recommendations by Tommi:",
      episode13_caption_0: "Tommi Laitio",
      episode13_summary: "",

      episode14_title:
        "14 \\ How does developing customer experience strengthen democracy?",
      episode14_paragraphs:
        "In this episode, our guest is Elina Piskonen, who is known as a pioneer in the service design of communication. In her role as the Customer Experience Manager at the Finnish National Agency for Education, Elina changes practices, work processes, and culture towards customer-centricity, and develops the customer experience of extensive digital systems with hands-on involvement. With Elina, we discuss customer experience, communication, and service design, and how combining these can strengthen democracy and the experience of being heard.",
      episode14_quote:
        "“There's a big difference between being heard and being listened to. And the experience of participation is then completely different. A lot of work needs to be done to ensure that there's an opportunity to listen at the right moments, that people know when they have the chance to participate, and that we also have the opportunity to utilize the information that comes from it.\"",
      episode14_materials_heading: "Material recommendations by Elina:",
      episode14_caption_0:
        "Elina Piskonen<br/>Customer Experience Manager, Opetushallitus",
      episode14_summary: "",

      episode15_title: "15 \\ How to strengthen city design maturity?",
      episode15_paragraphs:
        "In this episode, our guest is Katja Kumpulainen, a service designer working for the City of Tampere. Katja has spent nearly her entire career in various development roles working with people. What excites Katja the most is developing services together with customers and stakeholders. With Katja, we discuss design maturity and the role of service design, especially in an city context. In the episode, we delve into the research conducted by Katja and explore ways to strengthen design maturity within organizational processes and lower the barrier to design thinking in the workplace, both in the public and private sectors.",
      episode15_quote:
        '"Design maturity develops over time and can embrace new directions. There is no final endpoint, and one can always learn something new from design."',
      episode15_materials_heading: "Material recommendations by Katja:",
      episode15_caption_0: "Katja Kumpulainen<br/>Service designer",
      episode15_summary: "",

      episode16_title:
        "16 \\ How to foster a child-centered approach in service design?",
      episode16_paragraphs:
        "In this episode, our guest is the pioneer of child-centered service design, service designer Maija Hansen. Together, we discuss child-centered service design and developing services from a child's perspective. We explore what a child's service experience entails and how it can be supported. Maija has worked in the social and health sectors, non-profit organizations, and the public sector, with a special expertise in social design. Currently, Maija is working with the City of Helsinki in disability services, focusing on streamlining service pathways for children with disabilities.",
      episode16_quote:
        "\"If we look at services through the eyes of children, those services remain abstract and distant even if they are directly for children. One would hope that the child's experiential voice from those services would be heard better. Who leads the design of a child's service experience?\"",
      episode16_materials_heading: "Material recommendations by Maija:",
      episode16_caption_0: "Maija Hansen<br/>Service designer",
      episode16_summary: "",

      episode17_title:
        "17 \\ What does the city look like through the eyes of a young Designer?",
      episode17_paragraphs:
        "In this episode, LAB University of Applied Sciences alumni Henna Partanen and soon-to-be graduate Pyry Viitikko discuss city experience and design as a framework for developing cities.§§§" +
        "Henna Partanen is a designer who promotes social sustainability through high-quality and inclusive urban spaces and services. In her thesis Inclusive Urban Space – Human-centered urban design for Public Space, she studied the challenges of urban planning and designed a concept for a guide for developing inviting urban spaces that take into account the diverse needs of people.§§§" +
        "Pyry Viitikko is completing his final year of studies at the Institute of Design under the title of Service Designer. Pyry is interested in the overall well-being of people in cities, as well as the possibilities of developing a vibrant and active urban environment.§§§" +
        "Pyry and Henna discuss about experience from the perspective of different user groups in the city context, sharing their observations and experiences in different design projects. They reflect on how even small-scale interventions can be used to create a vibrant urban space, activate citizens, or increase opportunities for encounters and community.",
      episode17_quote:
        "“We often hear it said that a city is never complete and is always transforming – so we could change our viewpoint into recognizing that a city is not so rigid that we could not design it better for the future. And especially improve our processes to better take into account the needs of our citizens.”",
      episode17_materials_heading:
        "Material recommendations by Henna and Pyry:",
      episode17_caption_0: "Henna Partanen<br/>City designer",
      episode17_caption_1: "Pyyry Viitikko<br/>Service designer student",
      episode17_summary: "",

      episode18_title:
        "18 \\ How do art, creativity, and design come together in the development of future cities?",
      episode18_paragraphs: "TODO", // TODO E18 english
      episode18_quote: "“TODO”", // TODO E18 english
      episode18_materials_heading:
        "Material recommendations by Minna and Auri:",
      episode18_caption_0: "Auri Antinranta<br/>Futures artist, designer",
      episode18_caption_1: "Minna Koskelo<br/>Futures designer",
      episode18_summary: "",
    },
  },
};

const DETECTION_OPTIONS = {
  order: ["localStorage", "htmlTag", "cookie"],
  caches: ["localStorage", "cookie"],
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(
    {
      resources,
      detection: DETECTION_OPTIONS,
      fallbackLng: "fi",
      interpolation: {
        escapeValue: false,
      },
    },
    undefined
  );

export const setLanguage = (language: string) => {
  if (language !== "en" && language !== "fi") {
    return false;
  }

  i18n.language = language;
  return true;
};

export default i18n;
