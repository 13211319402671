export const DigitalistLogo = () => {
  return (
    <svg
      width="236"
      height="60"
      viewBox="0 0 236 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_34_1058)">
        <g clipPath="url(#clip1_34_1058)">
          <path
            d="M87.397 19.6701V13.1943L93.7093 13.1943V19.6701H87.397Z"
            fill="#EDEDED"
          />
          <path
            d="M125.537 19.6702V13.1943L131.849 13.1943V19.6702H125.537Z"
            fill="#EDEDED"
          />
          <path
            d="M187.773 19.6702V13.1943L194.086 13.1943V19.6702H187.773Z"
            fill="#EDEDED"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0L46.3519 0L46.3519 59.974L0 59.974L0 0ZM12.8207 46.9797H20.7104L20.7104 25.9887H12.8207L12.8207 46.9797ZM33.5311 46.9797H25.6415L25.6415 12.9944L33.5311 12.9944L33.5311 46.9797Z"
            fill="#EDEDED"
          />
          <path
            d="M69.9303 47.3547C63.2873 47.3547 59.1726 41.1744 59.1726 34.8293C59.1726 28.5922 63.2108 22.3038 69.9303 22.3038C72.5586 22.3038 75.2758 23.4178 77.0873 25.3583V13.1943H83.3996V46.8993L77.4429 46.8993L77.308 44.0881C75.5398 46.2886 72.6756 47.3547 69.9303 47.3547ZM71.3528 41.9802C75.1575 41.9802 77.3096 38.4174 77.3096 34.8293C77.3096 31.3038 75.2044 27.7239 71.3528 27.7239C67.5434 27.7239 65.4849 31.2488 65.4849 34.8293C65.4849 38.3452 67.4565 41.9802 71.3528 41.9802Z"
            fill="#EDEDED"
          />
          <path
            d="M87.397 46.8993V22.8047H93.7093V46.8993H87.397Z"
            fill="#EDEDED"
          />
          <path
            d="M108.931 57.3751C105.568 57.3751 101.726 56.4866 99.0174 54.369L101.24 49.7231C103.27 51.1792 105.838 51.9094 108.307 51.9094C112.431 51.9094 115.109 49.4938 115.109 45.3506V43.7021C113.438 45.8314 110.58 46.8993 107.952 46.8993C101.369 46.8993 96.6611 41.3705 96.6611 34.7837C96.6611 28.3766 100.987 22.3037 107.775 22.3037C110.574 22.3037 113.405 23.4853 115.286 25.6108L115.376 22.8047H121.422V45.0773C121.422 52.3593 116.254 57.3751 108.931 57.3751ZM109.108 41.7068C112.912 41.7068 115.287 38.5143 115.287 34.7837C115.287 31.1341 112.981 27.8605 109.108 27.8605C105.344 27.8605 102.973 31.12 102.973 34.7837C102.973 38.4228 105.231 41.7068 109.108 41.7068Z"
            fill="#EDEDED"
          />
          <path
            d="M125.537 46.8993V22.8047H131.849V46.8993H125.537Z"
            fill="#EDEDED"
          />
          <path
            d="M158.336 47.3547C151.815 47.3547 147.933 40.8705 147.933 34.7837C147.933 28.6008 151.919 22.3037 158.602 22.3037C161.452 22.3037 164.201 23.6042 165.893 25.9634V22.8047H172.161V46.8993H165.805V43.7512C164.02 46.0266 161.196 47.3547 158.336 47.3547ZM160.07 41.7524C163.911 41.7524 165.982 38.3168 165.982 34.7837C165.982 31.2418 163.779 27.8605 160.07 27.8605C156.331 27.8605 154.202 31.3429 154.202 34.7837C154.202 38.3365 156.331 41.7524 160.07 41.7524Z"
            fill="#EDEDED"
          />
          <path
            d="M176.756 46.8993V13.1943L183.068 13.1943V46.8993H176.756Z"
            fill="#EDEDED"
          />
          <path
            d="M187.773 46.8993V22.8047H194.086V46.8993H187.773Z"
            fill="#EDEDED"
          />
          <path
            d="M206.963 47.3547C203.244 47.3547 199.412 46.1941 197.005 43.1644L200.917 39.7028C202.598 41.536 204.895 42.5267 207.362 42.5267C208.73 42.5267 210.341 41.8808 210.341 40.2493C210.341 38.1185 207.291 37.7616 205.763 37.3343C201.913 36.3287 197.761 34.2593 197.761 29.5913C197.761 24.8522 202.628 22.3037 206.829 22.3037C210.244 22.3037 213.783 23.5526 216.209 26.0386L212.831 29.8645C211.32 28.4029 209.186 27.0861 207.052 27.0861C205.626 27.0861 203.895 27.568 203.895 29.2725C203.895 30.6919 205.147 31.2099 206.251 31.6409C210.346 33.1663 216.342 33.9968 216.342 39.6572C216.342 44.6675 211.435 47.3547 206.963 47.3547Z"
            fill="#EDEDED"
          />
          <path
            d="M229.687 46.8992V40.4233H236V46.8992H229.687Z"
            fill="#EDEDED"
          />
          <path
            d="M136.302 46.8993V13.1943L142.614 13.1943V22.8048H147.415V29.0548H142.614V46.8993H136.302Z"
            fill="#EDEDED"
          />
          <path
            d="M219.486 46.8993V13.1943L225.799 13.1943V22.8048H230.6V29.5203L225.799 29.5203V46.8993H219.486Z"
            fill="#EDEDED"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_34_1058">
          <rect width="236" height="60" fill="white" />
        </clipPath>
        <clipPath id="clip1_34_1058">
          <rect width="236" height="59.974" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
