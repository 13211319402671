import styled from "styled-components";
import { Colors, SmallText } from "../utils/Typography";
import { useTranslation } from "react-i18next";
import { OpenArrowBlack } from "../icons/OpenArrowBlack";

export const ButtonLink = styled.a`
  :hover {
    background-color: #9cd200;
  }

  :active {
    background-color: ${Colors.Black};

    p {
      color: ${Colors.Green};
    }

    svg {
      path {
        fill: ${Colors.Green};
      }
    }
  }
`;

export const LinkButton = styled.button`
  background-color: ${Colors.Green};
  padding: 16px;
  border: none;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  p {
    margin: 0 !important;
    font-weight: 700;
  }
`;

type Props = {
  link: string;
};

const SpotifyButton = ({ link }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <ButtonLink href={link} target="_blank" rel="noreferrer">
        <LinkButton>
          <SmallText>{t("listen")}</SmallText>
          <OpenArrowBlack />
        </LinkButton>
      </ButtonLink>
    </>
  );
};

export default SpotifyButton;
