import styled from "styled-components";
import { DigitalistLogoDark } from "../icons/DigitalisLogoDark";
import { DigitalistLogo } from "../icons/DigitalistLogo";
import { Breakpoint } from "../utils/BreakPoints";
import { Colors } from "../utils/Typography";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../icons/CloseIcon";
import { HamburgerIcon } from "../icons/HamburgerIcon";
import { useState } from "react";

const DesktopNavi = styled.div`
  z-index: 99;
  width: 100%;
  background-color: transparent;
  position: relative;
`;

const NaviContainer = styled.div`
  padding: 28px 16px;
  display: flex;
  justify-content: space-between;

  background-color: "transparent";

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    padding: 34px 24px;
  }

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    padding: 50px 34px;
  }

  @media only screen and (min-width: ${Breakpoint.Large}) {
    padding: 50px 48px;
  }

  transition: background-color 0.15s ease-out;
`;

const NavContent = styled.div<{ color: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: ${(props) => props.color};

  @media only screen and (max-width: ${Breakpoint.Tablet}) {
    color: ${Colors.White};

    * {
      color: ${Colors.White};
    }
  }
`;

const LogoIcon = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  width: 50%;

  * {
    color: ${(props) => props.color};
  }

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    * {
      color: ${(props) => props.color};
    }
  }

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    width: 70%;
  }

  @media only screen and (min-width: ${Breakpoint.Large}) {
    width: auto;
  }
`;

const Links = styled.div<{ dark?: boolean }>`
  display: flex;
  gap: 32px;

  a {
    color: #303030;
    text-decoration: none;
    padding: 8px 0 4px;
    border-bottom: 5px solid transparent;

    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 125%;

    @media only screen and (min-width: ${Breakpoint.Tablet}) {
      color: ${(props) => (props.dark ? "#303030" : "#EDEDED")};
    }

    @media only screen and (min-width: ${Breakpoint.Medium}) {
      font-size: 20px;
    }
  }
  a:hover {
    border-bottom: 5px solid ${Colors.Green};
  }
`;

const Languages = styled.div<{ dark?: boolean }>`
  display: flex;
  gap: 16px;
  align-items: center;
  color: #303030;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    margin-left: 48px;
    color: ${(props) => (props.dark ? "#303030" : "#EDEDED")};
  }

  p, div {
    color: #303030;
    padding: 8px 0 4px;
    margin: 0;
    border-bottom: 5px solid transparent;

    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 125%;
    cursor: pointer;

    @media only screen and (min-width: ${Breakpoint.Tablet}) {
      color: ${(props) => (props.dark ? "#303030" : "#EDEDED")};
    }

    @media only screen and (min-width: ${Breakpoint.Medium}) {
      font-size: 20px;
    }
  }

  p:hover {
    border-bottom: 5px solid ${Colors.Green};
  }

  div.border {
    border-right: 1px solid ${(props) => (props.dark ? "#303030" : "#EDEDED")};
  }
`;

const NaviRightSide = styled.div`
  display: none;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    display: inline-block;
    display: flex;
    align-items: center;
  }
`;

const HamburgerIconContainer = styled.div<{ color: string }>`
  cursor: pointer;

  * {
    color: ${(props) => props.color};
  }

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    display: none;
  }
`;

const MobileContainer = styled.div<{ open?: boolean }>`
  width: 100vw;
  height: 50vh;
  padding: 120px 0 32px;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;

  background-color: ${Colors.White};
  opacity: ${(props) => (props.open ? "1" : "0")};
  pointer-events: ${(props) => (props.open ? "all" : "none")};
  transition: opacity 0.15s ease-out;

  @media only screen and (min-width: ${Breakpoint.Tablet}) {
    display: none;
  }
`;

const MobileLinksWrapper = styled.div`
  padding: 0 16px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

type Props = {
  isDark?: boolean;
};

export const NavBar: React.FC<Props> = ({ isDark }) => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  const { t } = useTranslation();
  const lang = i18n.language;

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  if (typeof document !== "undefined") {
    if (isMobileMenuOpen) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  }

  return (
    <>
      <DesktopNavi>
        <NaviContainer>
          <NavContent color={Colors.Green}>
            <a href="/">
              <LogoIcon
                color={isMobileMenuOpen ? Colors.PureBlack : Colors.White}
              >
                {isDark || isMobileMenuOpen ? (
                  <DigitalistLogoDark />
                ) : (
                  <DigitalistLogo />
                )}
              </LogoIcon>
            </a>
            <NaviRightSide>
              <Links dark={isDark}>
                {lang === "en" ? (
                  <a href="https://digitalist.global">{t("about")}</a>
                ) : (
                  <a href="https://digitalist.global/fi">{t("about")}</a>
                )}
              </Links>
              <Languages dark={isDark}>
                <p onClick={() => changeLanguage("fi")}>FI</p>
                <div className='border'>&nbsp;</div>
                <p onClick={() => changeLanguage("en")}>EN</p>
              </Languages>
            </NaviRightSide>
            <HamburgerIconContainer
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              color={
                isMobileMenuOpen || isDark ? Colors.PureBlack : Colors.White
              }
            >
              {isMobileMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
            </HamburgerIconContainer>
          </NavContent>
        </NaviContainer>
      </DesktopNavi>
      <MobileContainer open={isMobileMenuOpen}>
        <MobileLinksWrapper>
          <Links dark={isDark}>
            <a href="https://digitalist.global/fi/paasivu/">{t("about")}</a>
          </Links>
          <Languages dark={isDark}>
            <p onClick={() => changeLanguage("fi")}>FI</p>|
            <p onClick={() => changeLanguage("en")}>EN</p>
          </Languages>
        </MobileLinksWrapper>
      </MobileContainer>
    </>
  );
};
