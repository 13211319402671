import { Colors, Paragraph } from "../utils/Typography";
import { TextWrapper } from "../EpisodePage";
import SpotifyButton from "./SpotifyButton";
import { PreviewText } from "../App";
import { useTranslation } from "react-i18next";
import { Episode } from "../react-app-env";
import styled from "styled-components";
import { Breakpoint } from "../utils/BreakPoints";

const PreviewButton = styled.div`
  background-color: ${Colors.PureBlack};

  width: fit-content;
  padding: 12px;

  display: flex;
  align-self: flex-end;
`;

const SummaryWrapper = styled.div`
  padding: 32px 0;

  @media only screen and (min-width: ${Breakpoint.Medium}) {
    padding: 0;
  }
`;

interface EpisodePlayProps {
  lang: string
  episode: Episode
}
export const EpisodePlay = ({ lang, episode }: EpisodePlayProps) => {
  const { t } = useTranslation()
  const now = new Date()
  const isLive = episode.date < now && episode.spotify

  return (
    isLive ? (
      <SpotifyButton link={episode.spotify!} />
    ) : (
      <PreviewButton>
        <PreviewText>
          {t("release")}{" "}
          {lang === "en" ? (
            <>
              {episode.date.toLocaleDateString("en-US", {
                month: "long",
                day: "2-digit",
              })}
            </>
          ) : (
            <>
              {episode.date.toLocaleDateString("fi-FI", {
                month: "long",
                day: "2-digit",
              })}
            </>
          )}
        </PreviewText>
      </PreviewButton>
    )
  )
}

type ShortSummaryProps = {
  lang: string;  
  text: string;
}

export const ShortSummary = (props: ShortSummaryProps) => {
  // const { t } = useTranslation();

  if (props.lang !== 'en') {
    return null; // summaries are only in english version
  }

  const paragraphs = props.text.split("\n")

  return (
    <>
      <TextWrapper>
        <SummaryWrapper>
          <Paragraph bold className="heading">
            Short summary of the episode:
          </Paragraph>
          {paragraphs.map((paragraph: string) => (
            <Paragraph>{paragraph}</Paragraph>
          ))}
        </SummaryWrapper>
      </TextWrapper>
    </>
  );
};
